import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, CardBody, Col, Container, Input, Label, Row, Table } from "reactstrap";
import "react-multi-date-picker/styles/layouts/mobile.css"
import Pagination from "../../Helpers/Components/Pagination";
import { postApiCall } from 'gfdu'
import { decrypt, encrypt } from "../../Helpers/Crypto";
import { useDispatch } from "react-redux";
import { contentLoadingChange } from '../../Reducer/componentSlice'
import { useNavigate } from "react-router-dom";

const List = () => {
    const [carList, setCarList] = useState([]);
    const dispatch = useDispatch()
    const [next, setNext] = useState(false)
    const navigate = useNavigate();
    // const [filterBox, setFilterBox] = useState(false)
    const [filter, setFilter] = useState({
        page: 0,
        count: 10,
        createdBy: "",
        bookingStage: "",
        filter: "",
        sortBy: "bookingId",
        sort: false
    })
    const dateFormat = (givenDat) => {
        const givenDate = new Date(givenDat)
        const date = givenDate?.getDate() > 9 ? givenDate?.getDate() : ("0" + givenDate?.getDate())
        const month = (givenDate?.getMonth() + 1) > 9 ? (givenDate?.getMonth() + 1) : ("0" + (givenDate?.getMonth() + 1))
        const year = givenDate?.getFullYear() + " " + givenDate?.getHours() + ":" + (givenDate?.getMinutes() > 9 ? givenDate?.getMinutes() : ("0" + givenDate?.getMinutes()))
        return date + "/" + month + "/" + year
    }
    const setPageNo = (value) => {
        setFilter((prev) => ({ ...prev, page: value }))
    }
    const getDetails = () => {
        dispatch(contentLoadingChange(false))
        const filterData = {
            sort: filter?.sort,
            ...(filter?.createdBy && { createdBy: filter?.createdBy }),
            ...(filter?.bookingStage && { bookingStage: filter?.bookingStage }),
            ...(filter?.filter && { filter: filter?.filter }),
            
        }
        postApiCall(`/admin/booking/list/${filter?.page}?count=${filter?.count}`, encrypt(filterData), (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setCarList(decryptedData?.data?.list)
                    setNext(decryptedData?.data?.next)
                } else {
                    setCarList([])
                    setNext(false)
                }
            } else {
                setCarList([])
                setNext(false)
            }
            dispatch(contentLoadingChange(false))
        })
    }
    useEffect(() => {
        getDetails()
    }, [filter])
    return (
        <React.Fragment>
            <div className="branch">
                <Container fluid>
                    <Row className="justify-content-between" >
                        <Col className='text-center'><h3>Booking</h3></Col>
                    </Row>
                </Container>
                <Card className="branch-card table-card" style={{ height: '100%' }}>
                    <CardBody className='table-content text-center'>
                        <Row xs={4} className='justify-content-between'>
                            <Col><Input className='search-box input-size-2' placeholder='Search...' value={filter?.filter} onChange={(e) => { setFilter(prev => ({ ...prev, filter: e.target.value })) }} /></Col>

                            <Col sm={1}><div className="d-flex justify-content-end"><Button className='button-theme' onClick={() => navigate("/booking/registration")} >Add</Button></div></Col>

                        </Row>
                        <Table className="table-bg" borderless >
                            <thead>
                                <tr className="border-bottom" >
                                    <th>
                                        <div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "bookingId" && filter?.sort === false ? "i-active" : ""} onClick={() => {
                                            setFilter((prev) => ({
                                                ...prev,
                                                sort: false
                                            }))
                                        }} >▲</i><i className={filter?.sortBy === "bookingId" && filter?.sort === true ? "i-active" : ""} onClick={() => {
                                            setFilter((prev) => ({
                                                ...prev,
                                                sort: true
                                            }))
                                        }} >▼</i>
                                        </div>
                                            Booking Id
                                        </div>
                                    </th>
                                    <th>Customer ID</th>
                                    <th>Name</th>
                                    <th>Car ID </th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Booking Stage</th>
                                    <th>Created By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    carList?.length ?
                                        carList?.map((element, i) => {
                                            return (
                                                <tr key={i} onClick={() => navigate(`/booking/view/${element?.bookingId}`)} className="cursor-pointer">
                                                    <td>{element?.bookingId}</td>
                                                    <td>{element?.customerId}</td>
                                                    <td className="text-capitalize">{element?.name}</td>
                                                    <td>{element?.carId}</td>
                                                    <td>{dateFormat(element?.preBookDetails?.startDate)}</td>
                                                    <td>{dateFormat(element?.preBookDetails?.endDate)}</td>
                                                    <td>{element?.bookingStage}</td>
                                                    <td>{element?.createdBy}</td>
                                                </tr>
                                            )
                                        })
                                        : <tr><td colSpan={8} className="text-center">No Data Found</td></tr>
                                }
                            </tbody>
                        </Table>
                        <Pagination
                            pageNo={filter?.page}
                            changePage={setPageNo}
                            next={next}
                        />
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default List; 