import shortid from 'shortid'

export const formatDate = (date) => {
    const convertedDate = new Date(date);
    return `${convertedDate?.getDate() < 10 ? "0" + convertedDate?.getDate() : convertedDate?.getDate()}-${convertedDate?.getMonth() < 9 ? "0" + (convertedDate?.getMonth() + 1) : (convertedDate?.getMonth() + 1)}-${convertedDate?.getFullYear()}`
}

export const textCapital = (value) => {
    const stringData = String(value)?.split(" ")
    const data = stringData?.map((value) => {
        if (value !== "" && value !== " " && value !== undefined) {
            return `${value[0]?.toUpperCase()}${value.slice(1, (value.length))?.toLowerCase()}`   
        }
    })
    return data?.join(" ")
}

export const parseDateString = (dateString) => {
    if (dateString !== undefined && dateString !== "") {
        const [day, month, year] = dateString?.split('-')?.map(Number);
        return new Date(Date.UTC(year, month - 1, day));
    } else {
        return false
    }
}
export const randomId = () => {
   return shortid.generate();
}

export const isTrimmed = (str) => {
    return !(str == str.trim());
  }

  export const extraHours = (date, hours) => {
    const extraDate = new Date(date)
    extraDate?.setHours(extraDate?.getHours() + Number(hours))
    extraDate.setMinutes(new Date(extraDate)?.getMinutes() + (60 - extraDate?.getMinutes()))
    return extraDate
}