import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import TablePlaceHolder from "../../Helpers/Components/TablePlaceHolder"
import Pagination from "../../Helpers/Components/Pagination";
import { contentLoadingChange } from '../../Reducer/componentSlice';
import { useDispatch } from "react-redux";
import { getApiCall } from "gfdu";
import { decrypt } from "../../Helpers/Crypto";
import { toast } from "react-toastify";

const EditLog = () => {
    const { id } = useParams()
    const [log, setLog] = useState([])
    const [pageNo, setPageNo] = useState(0)
    const [next, setNext] = useState(false)
    const [detail, setDetail] = useState({})
    const [logModal, setLogModal] = useState(false)
    const dispatch = useDispatch()

    const dateFormat = (date) => {
        const createdAt = new Date(date)
        return createdAt?.getDate() + "-" + (createdAt?.getMonth() + 1) + "-" + createdAt?.getFullYear() + " " + createdAt?.getHours() + ":" + createdAt?.getMinutes()
    }
    const renderTableRows = (data, parentKey = "") => {
        if (typeof data === "object" && data !== null) {
            return Object.entries(data)
                .filter(([_, value]) => value !== null && JSON.stringify(value) !== "{}")
                .map(([key, value], index) => {
                    const currentKey = parentKey ? `${parentKey} - ${key}` : key;
                    if (typeof value === "object" && value !== null) {
                        return (
                            <React.Fragment key={index}>
                                {renderTableRows(value, currentKey)}
                            </React.Fragment>
                        );
                    }
                    return (
                        <tr key={index}>
                            <td>{currentKey}</td>
                            <td>{value?.toString() || "N/A"}</td>
                        </tr>
                    );
                });
        }
        return null;
    };
    useEffect(() => {
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/associate/changes/log/${id}?pageNo=${pageNo}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setNext(decryptedData?.data?.next)
                    setLog(decryptedData?.data?.currentPage)
                } else {
                    setNext(false)
                    toast.error(response?.message)
                }
            }
            setNext(false)
            dispatch(contentLoadingChange(false))
        })
    }, [pageNo])

    return (
        <React.Fragment>
            <Modal isOpen={logModal} toggle={() => setLogModal(false)} size="lg">
                <ModalHeader toggle={() => setLogModal(false)}>{detail?.collectionName || ""}</ModalHeader>
                <ModalBody className="p-4 px-5">
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detail?.previousData ? (
                                renderTableRows(detail.previousData)
                            ) : (
                                <tr>
                                    <td colSpan="2" className="text-center">
                                        No previous data available.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
            <Row className='m-2 me-4'>
                <div>
                    <Card className='table-content'>
                        <CardBody>
                            <Table className="table-bg" borderless >
                                <thead>
                                    <tr className="border-bottom" >
                                        <th>S No</th>
                                        <th>User ID</th>
                                        <th>Action</th>
                                        <th>Updated At </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        log ? (log && log?.length !== 0 ? log?.map((value, key) => {
                                            return (
                                                <tr key={key} className="cursor-pointer-hover" onClick={() => { setDetail(value); setLogModal(true) }} >
                                                    <td>{(pageNo * 10) + (key + 1)}</td>
                                                    <td>{value?.userId}</td>
                                                    <td>{value?.action}</td>
                                                    <td>{dateFormat(value?.createdAt)}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                                            : <TablePlaceHolder count={4} />
                                    }
                                </tbody>
                            </Table>
                            <Pagination pageNo={pageNo} changePage={setPageNo} next={next} />
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </React.Fragment>
    )
}

export default EditLog;;