import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staffList : []
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    staffListChange : (state,{payload}) => {
      state.staffList = payload
    }
  }
});

export const { staffListChange } = staffSlice.actions
export default staffSlice.reducer;
