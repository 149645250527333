import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { useParams } from 'react-router-dom'
import Select from "react-select";
import { getApiCall, postApiCall, reloadWindowToPath } from 'gfdu';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../Helpers/Crypto';
import { useDispatch, useSelector } from 'react-redux';
import { contentLoadingChange } from '../../../Reducer/componentSlice';

const Index = () => {
    const { id } = useParams("id");
    const dispatch = useDispatch();
    const [variantData, setVariantData] = useState({})
    const [carData, setCarData] = useState({})
    const [associateId, setAssociateId] = useState("")
    const [buttonDisable, setButtonDisable] = useState(false)
    const setSelect = (e, name) => {
        setCarData((prev) => ({
            ...prev,
            [name]: e?.value
        }))
    }
    const handleChange = (e) => {
        setCarData((prev) => ({
            ...prev,
            [e?.target?.name]: e.target.value
        }))
    }
    const onSubmit = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (carData?.variantId === "" || carData?.variantId === undefined) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Car Details")
            setButtonDisable(false)
        } else if (!/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,3}[0-9]{4}$/?.test(carData?.registrationNo?.toUpperCase())) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Registration No")
            setButtonDisable(false)
        } else if (carData?.color === "" || carData?.color === undefined) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Colour Details")
            setButtonDisable(false)
        } else {
            const car = encrypt({
                variantId: carData?.variantId,
                registrationNo: carData?.registrationNo?.toUpperCase(),
                color: carData?.color?.toUpperCase()
            })
            if (associateId !== "" && associateId !== undefined && associateId !== null) {
                postApiCall(`/admin/bike/bikeAdd/${associateId}`, car, (response) => {
                    if (response?.success) {
                        const decryptedData = decrypt(response?.data)?.data
                        toast?.success("Bike Registered Successfully")
                        setTimeout(() => {
                            reloadWindowToPath(`/bike/${decryptedData?.bikeId}`)
                        }, 2000);
                    } else {
                        setButtonDisable(false)
                        toast?.error(response?.message || response?.msg || "Invalid Request")
                    }
                    dispatch(contentLoadingChange(false))
                })
            } else {
                postApiCall("/admin/bike/newBike", car, (response) => {
                    if (response?.success) {
                        const decryptedData = decrypt(response?.data)?.data
                        toast?.success("Bike Registered Successfully")
                        setTimeout(() => {
                            dispatch(contentLoadingChange(false))
                            reloadWindowToPath(`/bike/${decryptedData?.bikeId}`)
                        }, 2000);
                    } else {
                        dispatch(contentLoadingChange(false))
                        setButtonDisable(false)
                        toast?.error(response?.message || response?.msg || "Invalid Request")
                    }
                })
            }
        }
    }
    useEffect(() => {
        if (carData?.brandId) {
            getApiCall(`/admin/vehicle/brand/${carData?.brandId}`, (response) => {
                if (response?.success) {
                    setVariantData((prev) => ({
                        ...prev,
                        modelDetails: decrypt(response?.data)?.data?.models || []
                    }))
                } else {
                    toast.error("Unable to retrive model data")
                }
            })
        }
        if (carData?.modelId) {
            getApiCall(`/admin/vehicle/brand/model/${carData?.modelId}`, (response) => {
                if (response?.success) {
                    setVariantData((prev) => ({
                        ...prev,
                        variantDetails: decrypt(response?.data)?.data?.variants || []
                    }))
                } else {
                    toast.error("Unable to retrive model data")
                }
            })
        }
        if (carData?.variantId && carData?.fuelType === undefined) {
            const variant = variantData?.variantDetails?.filter((value) => value?.variantId === carData?.variantId)[0]
            const model = variantData?.modelDetails?.filter((value) => value?.modelId === carData?.modelId)[0]
            setCarData((prev) => ({
                ...prev,
                segment: model.segment || "",
                fuelType: variant?.fuelType || "",
                transmission: variant?.transmissionType || ""
            }))
        }
    }, [carData])
    useEffect(() => {
        if (id) {
            setAssociateId(id)
        }
        getApiCall("/admin/vehicle/brand?brandType=Bikes", (response) => {
            if (response?.success) {
                setVariantData((prev) => ({
                    brandDetails: decrypt(response?.data)?.data || [],
                    ...prev
                }))
            }
        })
    }, [])
    return (
        <React.Fragment>
            <Row className='mx-2  mb-5 justify-content-center'>
                <Col>
                    <Row><Col className='text-center text-theme fs-3'>{associateId ? `Associate Car` : "Own Car"}</Col></Row>
                    <Card className='dark-card py-3'>
                        <CardBody >
                            <Row sm={1} xl={2}>
                                <Col>
                                    <Row className='flex-column'>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="brand" sm={3}>Brand</Label>
                                                <Col sm={5}>
                                                    <Select id="brand" name="brand" autoComplete='off'
                                                        styles={{
                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                        }}
                                                        className='input-size-2'
                                                        options={[
                                                            ...(variantData?.brandDetails?.map(val => ({ label: val?.brand, value: val?.brandId })) || [])
                                                        ]}
                                                        onChange={(e) => setSelect(e, "brandId")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="model" sm={3}>Model</Label>
                                                <Col sm={5}>
                                                    <Select id="model" name="model" autoComplete='off'
                                                        styles={{
                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                        }}
                                                        className='input-size-2'
                                                        options={[
                                                            ...(variantData?.modelDetails?.map(val => ({ label: val?.modelName, value: val?.modelId })) || [])
                                                        ]}
                                                        onChange={(e) => setSelect(e, "modelId")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="variant" sm={3}>Variant</Label>
                                                <Col sm={5}>
                                                    <Select id="variant" name="variant" autoComplete='off'
                                                        styles={{
                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                        }}
                                                        className='input-size-2'
                                                        options={[
                                                            ...(variantData?.variantDetails?.map(val => ({ label: val?.variantName, value: val?.variantId })) || [])
                                                        ]}
                                                        onChange={(e) => setSelect(e, "variantId")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="segment" sm={3}>Segment</Label>
                                                <Col sm={5}>
                                                    <Input id="segment" name="segment" autoComplete='off' value={carData.segment || ''} disabled className='input-theme text-upper input-size-2' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="fuelType" sm={3}>Fuel</Label>
                                                <Col sm={5}>
                                                    <Input id="fuelType" name="fuelType" autoComplete='off' value={carData.fuelType || ''} disabled className='input-theme text-upper input-size-2' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="transmission" sm={3}>Transmission</Label>
                                                <Col sm={5}>
                                                    <Input id="transmission" name="transmission" autoComplete='off' value={carData.transmission || ''} disabled className='input-theme text-upper input-size-2' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='flex-column'>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="registrationNo" sm={3}>Registration No</Label>
                                                <Col sm={5}>
                                                    <Input id="registrationNo" name="registrationNo" autoComplete='off' onChange={handleChange} value={carData.registrationNo || ''} className='input-theme text-upper input-size-2' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="color" sm={3}>Colour</Label>
                                                <Col sm={5}>
                                                    <Input id="color" name="color" autoComplete='off' onChange={handleChange} value={carData.color || ''} className='input-theme text-upper input-size-2' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'><Button onClick={onSubmit} className='button-theme'>Register</Button></Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}



export default Index