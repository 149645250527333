import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, ButtonGroup } from 'reactstrap'
import { getApiCall, patchApiCall, reloadWindow } from 'gfdu'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { paymentDetailsChange, taxDetailsChange, dopChange } from '../../Reducer/siteDetailsSlice'
import { contentLoadingChange } from '../../Reducer/componentSlice'


const Payment = () => {
  const siteData = useSelector((state) => state.siteDetail)
  const dispatch = useDispatch()
  const [paymentDetails, setPaymentDetails] = useState({ razorpay: { publicKey: "", secretKey: "" } })
  const [taxDetails, setTaxDetails] = useState({ tax: { convenienceFee: "", gst: "", platformFee: "" } })
  const [dop, setDop] = useState({ dop: { town: "", city: "" } })
  const [disableButton, setDisableButton] = useState(false)
  const paymentHandleChange = (e) => {
    setPaymentDetails((prev) => ({
      ...prev,
      razorpay: {
        ...prev.razorpay,
        [e.target.name]: e.target.value
      },
    }))
  }
  const taxHandleChange = (e) => {
    if (e.target.value == Number(e.target.value)) {
      setTaxDetails((prev) => ({
        ...prev,
        tax: {
          ...prev.tax,
          [e.target.name]: e.target.value
        },
      }))
    }
  }
  const dopHandleChange = (e) => {
    if (e.target.value == Number(e.target.value)) {
      setDop((prev) => ({
        ...prev,
        dop: {
          ...prev.dop,
          [e.target.name]: e.target.value
        },
      }))
    }
  }
  // const codChange = (value) => {
  //   setPaymentDetails((prev) => ({
  //     ...prev,
  //     COD: value
  //   }))
  // }
  const onPaymentSubmit = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (paymentDetails?.razorpay?.publicKey === "") {
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
      toast.error("Invalid Public Key")
    } else if (paymentDetails?.razorpay?.secretKey === "") {
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
      toast.error("Invalid Secret Key")
    } else {
      patchApiCall("/admin/business/site/payment/update", encrypt(paymentDetails), (response) => {
        if (response?.success) {
          toast.success("Updated Successfully");
          setTimeout(() => {
            setDisableButton(false)
            reloadWindow()
          }, 2000);
        } else {
          setDisableButton(false)
          toast.error("Update Failed")
        }
        dispatch(contentLoadingChange(false))
      })
    }
  }
  const onTaxSubmit = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (taxDetails?.tax?.convenienceFee === "") {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid convenienceFee")
    } else if (Number(taxDetails?.tax?.convenienceFee) < 0 || Number(taxDetails?.tax?.convenienceFee) > 100 || !Number(taxDetails?.tax?.convenienceFee)) {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("convenienceFee between 1 to 100")
    } else if (taxDetails?.tax?.gst === "") {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid GST")
    } else if (Number(taxDetails?.tax?.gst) < 0 || Number(taxDetails?.tax?.gst) > 100 || !Number(taxDetails?.tax?.gst)) {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("GST between 1 to 100")
    } else if (taxDetails?.tax?.platformFee === "" || !Number(taxDetails?.tax?.platformFee)) {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid Platform Fee")
    } else {
      patchApiCall("/admin/business/site/tax/update", encrypt({ tax: { convenienceFee: Number(taxDetails?.tax?.convenienceFee), gst: Number(taxDetails?.tax?.gst), platformFee: Number(taxDetails?.tax?.platformFee) } }), (response) => {
        if (response?.success) {
          toast.success("Updated Successfully");
          setTimeout(() => {
            setDisableButton(false)
            reloadWindow()
          }, 2000);
        } else {
          setDisableButton(false)
          toast.error("Update Failed")
        }
        dispatch(contentLoadingChange(false))
      })
    }
  }
  const onDopSubmit = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (dop?.dop?.town === "") {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid Town price")
    } else if (!Number(dop?.dop?.town)) {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid DOP town price")
    } else if (dop?.dop?.city === "") {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid City price")
    } else if (!Number(dop?.dop?.city)) {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid DOP city price")
    } else {
      patchApiCall("/admin/business/site/dop/update", encrypt({ dop: { town: Number(dop?.dop?.town), city: Number(dop?.dop?.city) }}), (response) => {
        if (response?.success) {
          toast.success("Updated Successfully");
          setTimeout(() => {
            setDisableButton(false)
            reloadWindow()
          }, 2000);
        } else {
          setDisableButton(false)
          toast.error("Update Failed")
        }
        dispatch(contentLoadingChange(false))
      })
    }
  }
  useEffect(() => {
    getApiCall("/admin/business/site/payment/view", (response) => {
      if (response.success) {
        const decryptData = decrypt(response?.data)
        dispatch(paymentDetailsChange(decryptData?.data))
        setPaymentDetails(decryptData?.data)
      } else {
        toast.error("Failed to retrive details")
      }
    })
    getApiCall("/admin/business/site/tax/view", (response) => {
      if (response.success) {
        const decryptData = decrypt(response?.data)
        dispatch(taxDetailsChange({ tax: decryptData?.data }))
        setTaxDetails({ tax: decryptData?.data })
      } else {
        toast.error("Failed to retrive details")
      }
    })
    getApiCall("/admin/business/site/dop/view", (response) => {
      if (response.success) {
        const decryptData = decrypt(response?.data)
        dispatch(dopChange({ dop: decryptData?.data }))
        setDop({ dop: decryptData?.data })
      } else {
        toast.error("Failed to retrive details")
      }
    })
  }, [])
  return (
    <React.Fragment>
      <Container fluid>
        <Row className='flex-column'>
          <Col className='table-card' >
            <Card className='m-4 tab-card'>
              <CardBody>
                <Row className='justify-content-center' ><Col className='text-center fs-3 my-2' >Payment Details</Col></Row>
                <Row lg={2} className='mx-3' >
                  <Col className='py-4'>
                    <Row xs={1} className='justify-content-evenly'>
                      <Col>
                        <FormGroup row>
                          <Label sm={3}>Public Key</Label>
                          <Col><Input type='text' id='publicKey' name='publicKey' value={paymentDetails?.razorpay?.publicKey} onChange={paymentHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>Secret Key </Label>
                          <Col><Input type='text' id='secretKey' name='secretKey' value={paymentDetails?.razorpay?.secretKey} onChange={paymentHandleChange} /></Col>
                        </FormGroup>
                      </Col>

                    </Row>
                  </Col>
                </Row>
                <Row >
                  <Col className='text-center' ><Button className='button-theme' disabled={disableButton} onClick={onPaymentSubmit} >Payment Update</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className='table-card' >
            <Card className='m-4 tab-card'>
              <CardBody>
                <Row className='justify-content-center' ><Col className='text-center fs-3 my-2' >Tax Details</Col></Row>
                <Row lg={2} className='mx-3' >
                  <Col className='py-4'>
                    <Row xs={1} className='justify-content-evenly'>
                      <Col>
                        <FormGroup row>
                          <Label sm={3}>Convenience Fee (%)</Label>
                          <Col><Input type='text' id='convenienceFee' name='convenienceFee' value={taxDetails?.tax?.convenienceFee} onChange={taxHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>GST (%)</Label>
                          <Col><Input type='text' id='gst' name='gst' value={taxDetails?.tax?.gst} onChange={taxHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>Platform Fee</Label>
                          <Col><Input type='text' id='platformFee' name='platformFee' value={taxDetails?.tax?.platformFee} onChange={taxHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row >
                  <Col className='text-center' ><Button className='button-theme' disabled={disableButton} onClick={onTaxSubmit} >Tax Update</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className='table-card' >
            <Card className='m-4 tab-card'>
              <CardBody>
                <Row className='justify-content-center' ><Col className='text-center fs-3 my-2' >DOP Details</Col></Row>
                <Row lg={2} className='mx-3' >
                  <Col className='py-4'>
                    <Row xs={1} className='justify-content-evenly'>
                      <Col>
                        <FormGroup row>
                          <Label sm={3}>Town (Per KM)</Label>
                          <Col><Input type='text' id='town' name='town' value={dop?.dop?.town} onChange={dopHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>City (Per KM)</Label>
                          <Col><Input type='text' id='city' name='city' value={dop?.dop?.city} onChange={dopHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row >
                  <Col className='text-center' ><Button className='button-theme' disabled={disableButton} onClick={onDopSubmit} >Tax Update</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className='table-card' >
            <Card className='m-4 tab-card'>
              <CardBody>
                <Row className='justify-content-center' ><Col className='text-center fs-3 my-2' >Trip Protection Details</Col></Row>
                <Row lg={2} className='mx-3' >
                  <Col className='py-4'>
                    <Row xs={1} className='justify-content-evenly'>
                      <Col>
                        <FormGroup row>
                          <Label sm={3}>Town (Per KM)</Label>
                          <Col><Input type='text' id='town' name='town' value={dop?.dop?.town} onChange={dopHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>City (Per KM)</Label>
                          <Col><Input type='text' id='city' name='city' value={dop?.dop?.city} onChange={dopHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row >
                  <Col className='text-center' ><Button className='button-theme' disabled={disableButton} onClick={onDopSubmit} >Tax Update</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {console.log(taxDetails?.tax)}
    </React.Fragment>
  )
}

export default Payment;