import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Card, CardBody, Button, Modal, ModalBody, FormGroup, Label, Input, ModalHeader, Table } from 'reactstrap';
import { formatDate, textCapital } from '../../../Helpers/Utils';
import { toast } from 'react-toastify';
import { getApiCall, patchApiCall, reloadWindow } from 'gfdu';
import { decrypt, encrypt } from '../../../Helpers/Crypto';
import { bikeDataChange } from '../../../Reducer/bikeSlice';
import { contentLoadingChange } from '../../../Reducer/componentSlice'
import ImageUpload from './ImageUpload';
import EditDetails from './EditDetails';
import PlaceHolder from '../../../Helpers/Components/PlaceHolder';
import { useNavigate } from 'react-router-dom';
import { APIProvider, ControlPosition, MapControl, AdvancedMarker, Map, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import Config from '../../../Helpers/Config';
import TablePlaceHolder from "../../../Helpers/Components/TablePlaceHolder"
import Pagination from "../../../Helpers/Components/Pagination";


const CarDetails = (props) => {
    const dispatch = useDispatch()
    const inputRef = useRef()
    const imageRef = useRef()
    const bike = useSelector((state) => state.bike)
    const [bikeItems, setCarItems] = useState([])
    const [viewReason, setViewReason] = useState(false)
    const [disableButton, setButtonDisable] = useState(false)
    const [reasonToggle, setReasonToggle] = useState(false)
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [selectedImage, setSelectedImage] = useState("")
    const [imageOpen, setImageOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [openImage, setOpenImage] = useState(false)
    const [viewCar, setViewCar] = useState("")
    const [priceUpdate, setpriceUpdate] = useState(false)
    const [price, setPrice] = useState({ perHourKm: 0, perHourPrice: 0, package: 0 })
    const navigate = useNavigate();
    const [logModal, setLogModal] = useState(false)
    const [next, setNext] = useState(false)
    const [log, setLog] = useState([])
    const [pageNo, setPageNo] = useState(0)
    const [detail, setDetail] = useState({})
    const [view, setView] = useState(0)

    const dateFormat = (date) => {
        const createdAt = new Date(date)
        return createdAt?.getDate() + "-" + (createdAt?.getMonth() + 1) + "-" + createdAt?.getFullYear() + " " + createdAt?.getHours() + ":" + createdAt?.getMinutes()
    }
    const changeStatus = (status) => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (status === false && (bike?.bikeData?.reason === undefined || bike?.bikeData?.reason === "" || bike?.bikeData?.reason?.length <= 5)) {
            setButtonDisable(false)
            dispatch(contentLoadingChange(false))
            if (bike?.bikeData?.reason?.length !== 0 && bike?.bikeData?.reason?.length <= 5) {
                return toast.error("Reason is not enough")
            } else {
                return toast.error("Reason is required")
            }
        }
        patchApiCall(`/admin/bike/status/${props?.bikeId}?status=${status}`, encrypt({ ...(status === false ? { reason: bike?.bikeData?.reason } : {}) }), (response) => {
            if (response?.success) {
                toast.success(`${status ? "Active Successfully" : "Deactive Successfully"}`)
                setTimeout(() => {
                    reloadWindow()
                }, 2000);
            } else {
                setButtonDisable(false)
                toast.error(response.msg || response.message || "Status Couldn't Update")
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const handleChange = (e) => {
        dispatch(bikeDataChange({ [e?.target?.name]: e?.target?.value }))
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file !== undefined) {
            setViewCar(URL.createObjectURL(file))
            imageRef.current = file
        }
    }
    const updateImage = async () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        const formData = new FormData();
        formData.append("bike", imageRef.current)
        const imgId = (inputRef.current)?.split("/")?.pop()?.split(".")[0]
        patchApiCall(`/admin/bike/bikeImage/${props?.bikeId}?imageId=${imgId}`, formData, (response) => {
            if (response?.success) {
                toast.success("Update Successfully");
                setTimeout(() => {
                    reloadWindow()
                    setButtonDisable(false)
                }, 2000);
            } else {
                toast.error(response?.msg || response?.message || "Update Failed");
                setButtonDisable(false)
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const viewImage = (imgId) => {
        inputRef.current = imgId
        setViewCar(imgId)
        setOpenImage(true)
    }
    const handlePriceUpdate = () => {
        if (price?.perHourPrice <= 0 || !price?.perHourPrice) {
            toast.error("Invalid price")
        } else {
            setButtonDisable(true);
            patchApiCall(`/admin/bike/price/${props.bikeId}`, encrypt({ package: price?.package, perHourKm: price?.perHourKm, perHourPrice: price?.perHourPrice }), (response) => {
                if (response?.success) {
                    toast.success(response?.message)
                    setTimeout(() => {
                        reloadWindow();
                    }, 1500);
                } else {
                    setButtonDisable(false)
                    toast.error(response?.message)
                }
            })
        }
    }
    const renderTableRows = (data, parentKey = "") => {
        if (typeof data === "object" && data !== null) {
            return Object.entries(data)
                .filter(([_, value]) => value !== null && JSON.stringify(value) !== "{}")
                .map(([key, value], index) => {
                    const currentKey = parentKey ? `${parentKey} - ${key}` : key;
                    if (typeof value === "object" && value !== null) {
                        return (
                            <React.Fragment key={index}>
                                {renderTableRows(value, currentKey)}
                            </React.Fragment>
                        );
                    }
                    return (
                        <tr key={index}>
                            <td>{currentKey}</td>
                            <td>{value?.toString() || "N/A"}</td>
                        </tr>
                    );
                });
        }
        return null;
    };
    useEffect(() => {
        if (bike?.bikeView?.bikeImages && bike?.bikeView?.bikeImages?.length !== 0) {
            setCarItems(
                bike?.bikeView?.bikeImages?.map((value, key) => ({
                    src: value,
                    altText: `Bike ${key + 1}`,
                    key: `Bike ${key + 1}`
                }))
            )
        }
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/bike/changes/log/${bike?.bikeView?.bikeId}?pageNo=${pageNo}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setNext(decryptedData?.data?.next)
                    setLog(decryptedData?.data?.currentPage)
                    setDetail(decryptedData?.data?.currentPage?.length ? decryptedData?.data?.currentPage[0] : {})
                } else {
                    setNext(false)
                    toast.error(response?.message)
                }
            }
            dispatch(contentLoadingChange(false))
        })
    }, [pageNo])

    useEffect(() => {
        if (bikeItems?.length !== 0) {
            setSelectedImage(bikeItems[0])
        }
    }, [bikeItems])

    return (
        <React.Fragment>
            <Modal isOpen={viewReason} toggle={() => setViewReason(!viewReason)}>
                <ModalHeader toggle={() => setViewReason(!viewReason)} className='text-center' >Reason</ModalHeader>
                <ModalBody className='reason-modal'>{bike?.bikeView?.errorMsg}</ModalBody>
            </Modal>
            <Modal isOpen={reasonToggle} toggle={() => setReasonToggle(!reasonToggle)} >
                <ModalBody >
                    <Row className=' flex-column justify-content-center align-items-center'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="reason" sm={3}>Reason</Label>
                                <Col sm={7}>
                                    <Input id='reason' name='reason' className='input-theme' value={bike?.bikeData?.reason} onChange={handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col><div className='text-center' ><Button color='danger' disabled={disableButton} onClick={() => changeStatus(false)} >Deactive</Button></div></Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={imageOpen} size='xl' toggle={() => setImageOpen(!imageOpen)} >
                <ModalBody >
                    <Row className='my-3' ><Col className='text-center'><h4>Bike Upload</h4></Col></Row>
                    <ImageUpload bikeId={props?.bikeId} setImageOpen={setImageOpen} />
                </ModalBody>
            </Modal>
            <Modal isOpen={editOpen} size='xl' toggle={() => setEditOpen(!editOpen)}>
                <ModalBody >
                    {/* <Row className='my-3' ><Col className='text-center'><h4>Edit Details</h4></Col></Row> */}
                    <Row className='my-3' >
                        <Col className='text-center'>
                            <h5>Bike Id : {bike?.bikeView?.bikeId}</h5>
                        </Col>
                    </Row>
                    <EditDetails bikeId={props?.bikeId} setEditOpen={setEditOpen} />
                </ModalBody>
            </Modal>
            <Modal isOpen={openImage} size='xl' toggle={() => setOpenImage(!openImage)}>
                <ModalBody >
                    <Row className='flex-column' >
                        <Col><div className='view-bike'><img src={viewCar} alt="view" /></div></Col>
                        <Col className='text-center my-3'><div className='input-profile'><input type='file' id='photo' onChange={handleFileChange} /></div><Button tag={"label"} for="photo" className='button-theme'>Change</Button></Col>
                        {
                            viewCar === inputRef.current
                                ? "" :
                                <Col className='text-center my-2'><div className='d-flex justify-content-evenly'><Button className='button-theme' disabled={disableButton} onClick={updateImage}>Update </Button><Button color='danger' disabled={disableButton} onClick={() => setOpenImage(false)}>Close</Button></div></Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={priceUpdate} toggle={() => setpriceUpdate(!priceUpdate)}>
                <ModalBody className='pt-sm-5 px-4 text-center'>
                    <FormGroup row>
                        <Label for="perHourKm" md={3}> KM Per Hour </Label>
                        <Col md={0}>
                            <Input id="perHourKm" name="perHourKm" placeholder="KM Per Hour" type="text" value={price?.perHourKm} disabled onChange={(e) => { if (e.target.value === Number(e.target.value)) { setPrice((prev) => ({ ...prev, perHourKm: e.target.value })) } }} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="price" md={3}> Price </Label>
                        <Col md={0}>
                            <Input value={price?.perHourPrice} id='price' name='price' onChange={(e) => {
                                if (/^\d{0,}$/.test(e.target.value) || e.target.value === "") {
                                    setPrice((prev) => ({ ...prev, perHourPrice: e.target.value }))
                                }
                            }} disabled={disableButton} />
                        </Col>
                    </FormGroup>
                    <Button disabled={disableButton} className='mb-2 button-theme' onClick={handlePriceUpdate}>Update</Button>

                </ModalBody>
            </Modal>
            <Modal size='xl' isOpen={logModal} toggle={() => setLogModal(false)}>
                <ModalHeader>{bike?.bikeView?.bikeId}</ModalHeader>
                <ModalBody className=' px-4 text-center'>
                    <Row>
                        <Col md={7}>
                            <Table className="table-bg" borderless >
                                <thead>
                                    <tr className="border-bottom" >
                                        <th>Action</th>
                                        <th>Updated By</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        log ? (log && log?.length !== 0 ? log?.map((value, key) => {
                                            return (
                                                <tr key={key} className={`cursor-pointer-hover ${view === key ? "chosenLog" : ""}`} onClick={() => { setView(key);setDetail(value); setLogModal(true) }} >
                                                    <td>{value?.action}</td>
                                                    <td>{value?.userId}</td>
                                                    <td>{dateFormat(value?.createdAt)}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                                            : <TablePlaceHolder count={4} />
                                    }
                                </tbody>
                            </Table>
                            <Pagination pageNo={pageNo} changePage={setPageNo} next={next} />
                        </Col>
                        <Col md={5}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail?.previousData ? (
                                        renderTableRows(detail.previousData)
                                    ) : (
                                        <tr>
                                            <td colSpan="2" className="text-center">
                                                No previous data available.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Row className="m-2" >
                <Col lg={7} className='my-2'>
                    <Card className='bike-main-view' >
                        <CardBody>
                            <div >
                                {
                                    bike?.bikeView?.bikeImages && bike?.bikeView?.bikeImages?.length !== 0 ?
                                        <div className='bike-image-view'>
                                            <div className='bike-image-main'>
                                                <div className='bike-hover-image'>
                                                    <Button className='button-theme mx-1' onClick={() => viewImage(selectedImage?.src)} >View</Button>
                                                </div>
                                                <img src={selectedImage?.src || ""} alt={selectedImage?.altText} />
                                            </div>
                                            {
                                                bikeItems && bikeItems?.length !== 0 &&
                                                bikeItems?.map((value, i) => {
                                                    if (i < 10) {
                                                        return (
                                                            <div onClick={() => setSelectedImage(value)} className={`bike-images ${selectedImage?.key === value?.key ? "active-bike-image" : ""}`} key={value?.key}>
                                                                {selectedImage?.key === value?.key ? <div className='blur' ><i className='text-white bx bx-expand'></i></div> : ""}
                                                                <img src={value?.src} alt={value?.altText} />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div> :
                                        <div className='image-upload'>
                                            <Button className='button-theme' onClick={() => setImageOpen(true)} >Upload Images</Button>
                                        </div>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={5} className='my-2'>
                    <Card className='bike-main-view' >
                        <CardBody>
                            <Row className='flex-column'>
                                <Col>
                                    <div className='profile-data mb-3'><span>Bike ID</span><span>{(props?.loading ? (bike?.bikeView?.bikeId || "-") : <PlaceHolder />)} <i className='ms-2 profile-edit bx bx-edit' onClick={() => setEditOpen(true)} ></i></span></div>
                                    <div className='profile-data mb-3'><span>Owned By</span><span>{(props?.loading ? (textCapital(bike?.bikeView?.ownerType) || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Created By</span><span>{(props?.loading ? (textCapital(bike?.bikeView?.createdBy) || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Created At</span><span>{(props?.loading ? (formatDate(bike?.bikeView?.createdAt) || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Registration No</span><span>{(props?.loading ? (bike?.bikeView?.registrationNo?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Brand</span><span>{(props?.loading ? ((bike?.bikeView?.brand)?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Model</span><span>{(props?.loading ? ((bike?.bikeView?.modelName)?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Segment</span><span>{(props?.loading ? ((bike?.bikeView?.segment)?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Transmission</span><span>{(props?.loading ? ((bike?.bikeView?.transmissionType)?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Fuel</span><span>{(props?.loading ? (bike?.bikeView?.fuelType || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Colour</span><span>{props?.loading ? ((bike?.bikeView?.color)?.toUpperCase() || "-") : <PlaceHolder />}</span></div>
                                    <p className='profile-data'>
                                        <span>Status</span>
                                        <span>
                                            {
                                                !bike?.bikeView?.status ?
                                                    <Button color="success" disabled={disableButton} onClick={() => changeStatus(true)}>Active</Button> :
                                                    <Button color="danger" onClick={() => setReasonToggle(true)}>Deactive</Button>
                                            }
                                        </span>
                                    </p>
                                    {
                                        bike?.bikeView?.status === false && <p className='profile-data' ><span>Reason</span><span><Button className='button-theme' onClick={() => setViewReason(!viewReason)}>View</Button></span></p>
                                    }
                                    {
                                        bike?.bikeView?.bikeImages && bike?.bikeView?.bikeImages?.length !== 0 && bike?.bikeView?.deviceId === undefined && bike?.bikeView?.location === undefined &&
                                        <p className='profile-data'>
                                            <span>Device</span>
                                            <span><Button color="success" onClick={() => navigate(`/bike/device/${props?.bikeId}`)}>Register</Button></span>
                                        </p>
                                    }
                                    {
                                        bike?.bikeView?.deviceId !== undefined && bike?.bikeView?.location !== undefined &&
                                        <p className='profile-data'>
                                            <span>Verifiy</span>
                                            <span>{bike?.bikeView?.verified ? "Verified" : <Button color="success" onClick={() => navigate(`/bike/verify/${props?.bikeId}`)}>Verify</Button>}</span>
                                        </p>
                                    }
                                    <p className='profile-data'>
                                        <span>Log</span>
                                        <span className='d-flex align-items-center'> <i className='bx-sm cursor-pointer bx bx-info-circle' onClick={() => { setLogModal(true) }}></i> </span>
                                    </p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                bike?.bikeView?.location && (
                    <Row className="m-2 mb-5">
                        <Col>
                            <Card >
                                <CardBody>
                                    <Row xs={1} sm={3} className='mx-2' >
                                        <Col xs={1} sm={8} className='verify-map-selection'>
                                            <APIProvider apiKey={Config?.KEYS?.MAP_KEY}>
                                                {bike?.bikeView?.location ? (
                                                    <Map mapId={"bf51a910020fa25a"} defaultZoom={10} defaultCenter={{ lat: parseFloat(bike?.bikeView.location.lat), lng: parseFloat(bike?.bikeView.location.lng) }} gestureHandling={"cooperative"} mapTypeControl={true} zoomControl={true} disableDefaultUI={true}>
                                                        <AdvancedMarker ref={markerRef} position={{ lat: parseFloat(bike?.bikeView.location.lat), lng: parseFloat(bike?.bikeView.location.lng) }} />
                                                        <MapControl position={ControlPosition.TOP}></MapControl>
                                                    </Map>
                                                ) : (
                                                    <p>Location data is not available</p>
                                                )}
                                            </APIProvider>
                                        </Col>
                                        <Col xs={1} sm={4}>
                                            <p className='profile-data'><span>Device Id</span><span>{(bike?.bikeView?.deviceId)}</span></p>
                                            <p className='profile-data'><span>Start KM</span><span>{(bike?.bikeView?.startKm)}</span></p>
                                            <p className='profile-data'><span>Current KM</span><span>{(bike?.bikeView?.currentKm || bike?.bikeView?.startKm) || ""}</span></p>
                                            <p className='profile-data'><span>Insurance End</span><span>{formatDate(bike?.bikeView?.insuranceDate) || ""}</span></p>
                                            <p className='profile-data'><span>Latitude</span><span>{(bike?.bikeView?.location?.lat)}</span></p>
                                            <p className='profile-data'><span>Longitude</span><span>{(bike?.bikeView?.location?.lng)?.toUpperCase()}</span></p>
                                            <p className='profile-data'><span>Street</span><span>{bike?.bikeView?.location?.street && textCapital(bike?.bikeView?.location?.street)}</span></p>
                                            <p className='profile-data'><span>Locality</span><span>{bike?.bikeView?.location?.locality && textCapital(bike?.bikeView?.location?.locality)}</span></p>
                                            <p className='profile-data'><span>City</span><span>{bike?.bikeView?.location?.city && textCapital(bike?.bikeView?.location?.city)}</span></p>
                                            <p className='profile-data'><span>Country</span><span>{bike?.bikeView?.location?.country && textCapital(bike?.bikeView?.location?.country)}</span></p>
                                            <p className='profile-data'><span>Pincode</span><span>{bike?.bikeView?.location?.pincode && textCapital(bike?.bikeView?.location?.pincode)}</span></p>
                                            {
                                                bike?.bikeView?.verified ?
                                                    <Table className="table-bg" borderless>
                                                        <thead>
                                                            <tr className="border-bottom text-middle">
                                                                <th className='text-center'>S.No</th>
                                                                <th className='text-center'>KM Per Hour</th>
                                                                <th className='text-center'>Price</th>
                                                                <th className='text-center'>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                bike?.bikeView && bike?.bikeView?.priceTypes ? bike?.bikeView?.priceTypes?.map((value, i) => (
                                                                    <tr key={i + 1}>
                                                                        <td className='text-center'>{i + 1}</td>
                                                                        <td className='text-center'>{value.perHourKm}</td>
                                                                        <td className='text-center'>{value?.perHourPrice}</td>
                                                                        <td className="text-center"><i className='bx bxs-edit profile-edit cursor-pointer ' onClick={() => { setPrice({ perHourKm: value?.perHourKm, perHourPrice: value?.perHourPrice, package: value?.package }); setpriceUpdate(true) }}></i></td>
                                                                    </tr>
                                                                )) :
                                                                    <tr>
                                                                        <td colSpan={3} className='text-center'>No Data Found</td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </Table> : ""
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )

            }
        </React.Fragment>
    );
}

export default CarDetails;