import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select"
import { getApiCall, postApiCall, reloadWindowToPath } from "gfdu";
import { toast } from "react-toastify";
import { decrypt, encrypt } from "../../Helpers/Crypto"
import { useDispatch } from "react-redux";
import { contentLoadingChange } from '../../Reducer/componentSlice'

const Registration = () => {
    const [buttonDisable, setButtonDisable] = useState(false)
    const [staff, setStaff] = useState({ name: "" })
    const [roles, setRoles] = useState([])
    const dispatch = useDispatch()

    const handleChange = (e) => {
        setStaff((prev => ({ ...prev, [e.target.name]: e.target.value })))
    }
    const onSubmit = () => {
        if (!/^[a-zA-Z\s]{2,}$/.test(staff?.name) || !staff?.name?.trim()) {
            toast.error("Invalid Name");
            return false;
        } else if (!/^[6-9]{1}[0-9]{9}$/.test(staff?.phone)) {
            toast.error("Invalid Phone Number");
            return false;
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(staff?.email)) {
            toast.error("Invalid Email Id");
            return false;
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(staff?.password)) {
            toast.error("Invalid Password")
        } else if (!staff?.role) {
            toast.error("Chose a role")
        } else {
            dispatch(contentLoadingChange(true))
            setButtonDisable(true)
            postApiCall(`/admin/staff/register`, encrypt({ name: staff?.name, phone: staff?.phone, email: staff?.email, password: staff?.password, roleId: staff?.role?.value }), (response) => {
                dispatch(contentLoadingChange(false))
                if (response?.success) {
                    setTimeout(() => {
                        reloadWindowToPath("/staff")
                    }, 1500);
                } else {
                    setButtonDisable(false)
                    toast.error(response?.message)
                }
            })
        }
    }
    useEffect(() => {
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/staff/roles/list`, (response) => {
            dispatch(contentLoadingChange(false))
            if (response?.success) {
                setRoles(decrypt(response?.data)?.data)
            } else {
                setRoles([])
            }
        })
    }, [])

    return (
        <React.Fragment>
            <Row className='justify-content-center mb-5'>
                <Col lg={10}>
                    <Row><Col className='text-center text-theme fs-3'>Staff Registration</Col></Row>
                    <Card>
                        <CardBody>
                            <Row className='my-2 flex-column'>
                                <Col>
                                    <FormGroup row className='justify-content-center'>
                                        <Label for="name" sm={3}>Name</Label>
                                        <Col sm={5}>
                                            <Input disabled={buttonDisable} id="name" name="name" autoComplete='off' onChange={handleChange} value={staff.name || ''} className='input-theme text-upper' type="text" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row className='justify-content-center'>
                                        <Label for="phone" sm={3}>Phone</Label>
                                        <Col sm={5}>
                                            <Input disabled={buttonDisable} id="phone" name="phone" maxLength={10} autoComplete='off' onChange={(e) => { if (e.target.value == Number(e.target.value)) { handleChange(e) } }} value={staff.phone || ''} className='input-theme text-upper' type="text" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row className='justify-content-center'>
                                        <Label for="email" sm={3}>Email</Label>
                                        <Col sm={5}>
                                            <Input disabled={buttonDisable} id="email" name="email" autoComplete='off' onChange={handleChange} value={staff.email || ''} className='input-theme text-lower' type="text" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row className='justify-content-center'>
                                        <Label for="password" sm={3}>Password</Label>
                                        <Col sm={5}>
                                            <Input disabled={buttonDisable} id="password" name="password" autoComplete='off' onChange={handleChange} value={staff.password || ''} className='input-theme' type="text" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row className='justify-content-center'>
                                        <Label for="role" sm={3}>Roles</Label>
                                        <Col sm={5}>
                                            <Select
                                                styles={{
                                                    control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                    option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                }}
                                                isDisabled={buttonDisable}
                                                onChange={(e) => setStaff(prev => ({ ...prev, role: e }))}
                                                options={[
                                                    ...(roles?.map(val => ({ label: val?.roleName, value: val?.roleId })) || [])
                                                ]} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <div className='text-center my-3'>
                                        <Button className='button-theme' disabled={buttonDisable} onClick={onSubmit}>Register</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Registration