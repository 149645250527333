import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select"
import { getApiCall, postApiCall, reloadWindowToPath } from "gfdu";
import { toast } from "react-toastify";
import { decrypt, encrypt } from "../../../Helpers/Crypto"
import { useDispatch } from "react-redux";
import { contentLoadingChange } from '../../../Reducer/componentSlice'

const Register = () => {
    const [buttonDisable, setButtonDisable] = useState(false)
    const [roleName, setRoleName] = useState("")
    const dispatch = useDispatch()
    const [role, setRole] = useState([{ controller: "", action: [] }])

    const controller = [
        { label: "Car", value: "car" },
        { label: "Customer", value: "customer" },
        { label: "Bike", value: "Bike" },
        { label: "Booking", value: "booking" }

    ]
    const handleChange = (e, i) => {
        const check = role?.some((element, index) => {
            if (element?.controller === e.value && index !== i) {
                return true
            }
        })
        if (check) {
            toast.error("Controller is already chosen")
        } else {
            const updatedRole = role?.map((element, index) => {
                if (i === index) {
                    return { controller: e.value, action: element?.action }
                } else {
                    return element
                }
            })
            setRole(updatedRole)
        }
    }
    const handleActionChange = (e, i) => {
        const updatedRole = role?.map((element, index) => {
            if (i === index) {
                return { controller: element?.controller, action: Array.isArray(e) ? e.map(x => x.value) : [] }
            } else {
                return element
            }
        })
        setRole(updatedRole)
    }
    const action = [
        { label: "Read", value: "read" },
        { label: "Write", value: "write" },
        { label: "Update", value: "update" }
    ]
    const onSubmit = () => {
        if (!/^[a-zA-Z\s]{2,}$/.test(roleName) || !roleName?.trim()) {
            toast.error("Invalid Name");
        } else {
            const check = role?.some((element, index) => {
                if (!element?.controller || !element?.action?.length) {
                    return true
                }
            })
            if (check) {
                toast.error('Choose proper values')
            } else {
                dispatch(contentLoadingChange(true))
                setButtonDisable(true)
                postApiCall(`/admin/staff/role`, encrypt({ roleName: roleName, permission: role}), (response) => {
                    dispatch(contentLoadingChange(false))
                    if (response?.success) {
                        setTimeout(() => {
                            reloadWindowToPath("/staff/role")
                        }, 1500);
                    } else {
                        setButtonDisable(false)
                        toast.error(response?.message)
                    }
                })
            }
        }
    }
    const handleNextSelect = () => {
        if (!role[role?.length - 1]?.controller) {
            toast.error("Choose role")
        } else if (!role[role?.length - 1]?.action?.length) {
            toast.error("Choose atleast one action")
        } else {
            setRole((prev) => ([...prev, { controller: "", action: [] }]))
        }

    }

    return (
        <React.Fragment>
            <Row className='justify-content-center mb-5'>
                <Col lg={10}>
                    <Row><Col className='text-center text-theme fs-3'>Role Register</Col></Row>
                    <Card>
                        <CardBody>
                            <Row className='my-2 flex-column'>
                                <Col>
                                    <FormGroup row className='justify-content-center mt-2'>
                                        <Label for="name" sm={3}>Name</Label>
                                        <Col sm={5}>
                                            <Input disabled={buttonDisable} id="name" name="name" autoComplete='off' onChange={(e) => setRoleName(e.target.value)} value={roleName || ''} className='input-theme text-upper' type="text" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row className='justify-content-center'>
                                        <Label for="name" sm={3}>Role</Label>
                                        <Col sm={5}>
                                            {
                                                (role && role?.length > 0) ?
                                                    role?.map((element, i) => {
                                                        return (
                                                            <Row key={i} className="mt-2">
                                                                <Col className="me-2">
                                                                    <Select
                                                                        styles={{
                                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                                        }}
                                                                        options={controller}
                                                                        isDisabled={buttonDisable}
                                                                        onChange={(e) => handleChange(e, i)}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Select
                                                                        styles={{
                                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                                            valueContainer: ((style) => ({ ...style, overflow: "scroll" })),
                                                                            multiValueLabel: ((style) => ({ ...style, backgroundColor: "#ff4d32", borderRadius: " 2px 0 0 2px ", color: "white", })),
                                                                            multiValueRemove: ((style) => ({ ...style, border: "solid #ff4d32", borderRadius: "0 2px 2px 0", backgroundColor: "white", color: "ff4d32", }))
                                                                        }}
                                                                        isDisabled={buttonDisable}
                                                                        onChange={(e) => handleActionChange(e, i)}
                                                                        isMulti options={action} />
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }) : ""
                                            }
                                            {
                                                controller?.length > role?.length &&
                                                <Row className="p-0 text-end" sm={1}>
                                                    <i className='bx cursor-pointer bx-plus bx-md' onClick={() => { handleNextSelect() }}></i>
                                                </Row>
                                            }
                                        </Col>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <div className='text-center my-3'>
                                        <Button className='button-theme' disabled={buttonDisable} onClick={onSubmit}>Register</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Register