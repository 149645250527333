import React, { useCallback } from "react";
import { postApiCall, getApiCall } from "gfdu";
import { decrypt, encrypt } from "../Crypto";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

const PlaceInput = (props) => {
    const loadOptions = useCallback(
        debounce((inputValue, callback) => {
            if (inputValue.trim()) {
                postApiCall("/admin/booking/search",
                    encrypt({ data: inputValue }),
                    (response) => {
                        if (response?.success) {
                            const decryptedResponse = decrypt(response?.data);
                            const predictions = decryptedResponse?.data?.predictions || [];
                            if (Array.isArray(predictions)) {
                                const placeList = predictions.map((item) => ({
                                    label: item?.description || "Unknown Place",
                                    value: item?.place_id || "unknown-id",
                                }));
                                callback(placeList);
                            } else {
                                callback([]);
                            }
                        } else {
                            toast.error("Failed to load places.");
                            callback([]);
                        }
                    },
                    (error) => {
                        toast.error("An error occurred while fetching places.");
                        callback([]);
                    }
                );
            } else {
                callback([]);
            }
        }, 300),
        []
    );

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            const selectedValue = selectedOption.value;
            if (selectedValue) {
                getApiCall(`/admin/booking/details/${selectedValue}`, (response) => {
                    if (response?.success) {
                        const decryptedResponse = decrypt(response?.data)?.data || {};
                        if (decryptedResponse?.status) {
                            if (props?.setPlace) {
                                props?.setPlace(decryptedResponse?.result?.geometry?.location)
                            }
                        } else {
                            console.warn("Status property is missing in the details response");
                        }
                    } else {
                        toast.error("Cannot find location details.");
                    }
                });
            }
        }
    };

    return (
        <div style={{ position: "relative", width: "300px" }}>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                onChange={handleChange}
                placeholder="Search and select a place..."
                isClearable
                styles={{
                    container: (base) => ({
                        ...base,
                        width: "100%",
                    }),
                    input: (base) => ({
                        ...base,
                        padding: "8px",
                    }),
                }}
            />
        </div>
    );
};

export default PlaceInput;