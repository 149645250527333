import { getApiCall, postApiCall, reloadWindow, reloadWindowToPath } from "gfdu";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { decrypt, encrypt } from "../../../Helpers/Crypto";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import PlaceInput from "../../../Helpers/Components/PlaceInput";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import moment from 'moment'

const ChooseCustomer = () => {
    const { carId } = useParams();
    const [searchParams] = useSearchParams()
    const [customer, setCustomer] = useState([])
    const [car, setCar] = useState()
    const dispatch = useDispatch();
    const [data, setData] = useState({
        startDate: searchParams.get("startDate"),
        endDate: searchParams.get('endDate'),
        package: searchParams.get('package')
    })
    const [prebook, setPrebook] = useState({ totalHours: 8, perHourPrice: 0, package: "Package 1", from: "", to: "" })
    const customerOption = customer?.length ?
        customer?.map(element => {
            return { label: element?.name?.toUpperCase(), value: element?.customerId?.toUpperCase() }
        })
        : { label: "Select", value: "Select" }

    const handleRadioChange = (value, i) => {
        setPrebook((prev) => ({ ...prev, package: value, actualPrice: Math.ceil(prebook?.totalHours * car?.priceTypes[i]?.perHourPrice), perHourPrice: car?.priceTypes[i]?.perHourPrice }))
    }
    const handleRegister = () => {
        dispatch(contentLoadingChange(true))
        if (!prebook?.customerId) {
            toast.error("Choose customer")
            dispatch(contentLoadingChange(false))
        } else {
            const data = {
                carId: carId,
                customerId: prebook?.customerId,
                package: prebook?.package,
                startDate: prebook?.from,
                endDate: prebook?.to
            }
            postApiCall(`/admin/booking/prebook`, encrypt(data), (response) => {
                if (response?.success) {
                    reloadWindowToPath('/booking')
                } else {
                    dispatch(contentLoadingChange(false))
                    toast.error(response?.message)
                }
            })
        }
    }
    useEffect(() => {
        getApiCall(`/admin/customer/select`, (response) => {
            if (response?.success) {
                setCustomer(decrypt(response?.data)?.data)
            } else {
                setCustomer([])
            }
        })
        getApiCall(`/admin/car/${carId}`, (response) => {
            if (response?.success) {
                const carDetails = decrypt(response?.data)?.data
                setCar(carDetails)
                if (data?.startDate && data?.endDate) {
                    let start = moment(Number(data?.startDate));
                    let end = moment(Number(data?.endDate));
                    let diff = end.diff(start);
                    let hours = Math.abs(diff / (1000 * 60 * 60));
                    const amount = carDetails?.priceTypes[data?.package?.split(" ")[1] - 1]?.perHourPrice * Math.ceil(hours);
                    setPrebook((prev) => ({ ...prev, from: new Date(start.format()), to: new Date(end.format()), perHourPrice:carDetails?.priceTypes[data?.package?.split(" ")[1] - 1]?.perHourPrice, totalHours: Math.ceil(hours), actualPrice: amount, package: data?.package }))
                }
            } else {
                toast.error(response?.message)
            }
        })
    }, [])
    useEffect(() => {
        const differenceInMilliseconds = prebook?.to - prebook?.from;
        const hours = differenceInMilliseconds / (1000 * 60 * 60);
        setPrebook((prev) => ({ ...prev, totalHours: Math.ceil(hours), actualPrice: Math.ceil(hours) * prebook?.perHourPrice }))
    }, [prebook?.to, prebook?.from])
    return (
        <React.Fragment>
            <div className="branch">
                <Card className="branch-card p-3 ">
                    <CardBody >
                        <Row sm={1} xl={2}>
                            <Col>
                                <Row className='flex-column'>
                                    <Col>
                                        <FormGroup row className='justify-content-center'>
                                            <Label for="brand" sm={3}>Car ID</Label>
                                            <Col sm={5}>
                                                <Input className='input-theme text-upper input-size-2' type="text" disabled value={carId} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="customer" sm={3}> Customer</Label>
                                            <Col sm={5}>
                                                {
                                                    customer && customer?.length ?

                                                        <Select id="customer" name="customer" autoComplete='off'
                                                            styles={{
                                                                control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                                option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                            }}
                                                            options={customerOption}
                                                            onChange={(e) => { setPrebook(prev => ({ ...prev, customerId: e?.value })) }}
                                                            className='input-size-2'
                                                        /> :
                                                        <Label className="red mb-0">* Customers not found</Label>
                                                }
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="delivery" sm={3}> Package (km/hr) </Label>
                                            <Col sm={5}>
                                                <FormGroup tag="fieldset" className="d-flex justify-content-between marginClose">
                                                    <FormGroup check>
                                                        <Input onChange={() => handleRadioChange("Package 1", 0)} checked={prebook?.package === "Package 1"} id="15" name="radio1" type="radio" />
                                                        {' '}
                                                        <Label htmlFor="15" check> 15 </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input onChange={() => handleRadioChange("Package 2", 1)} checked={prebook?.package === "Package 2"} id="20" name="radio1" type="radio" />
                                                        {' '}
                                                        <Label htmlFor="20" check> 20 </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input onChange={() => handleRadioChange("Package 3", 2)} checked={prebook?.package === "Package 3"} id="25" name="radio1" type="radio" />
                                                        {' '}
                                                        <Label htmlFor="25" check> 25 </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className='flex-column'>
                                    <Col>
                                        <FormGroup row className='justify-content-center'>
                                            <Label for="brand" sm={3}>From</Label>
                                            <Col sm={5}>
                                                <DatePicker
                                                    className="rmdp-mobile"
                                                    plugins={[<TimePicker position="bottom" />]}
                                                    mobileLabels={{
                                                        OK: "Accept",
                                                        CANCEL: "Close",
                                                    }}
                                                    minDate={new Date(new Date().setHours(new Date().getHours() + 2))}
                                                    onChange={(e) => { setPrebook((prev) => ({ ...prev, from: new Date(e), to: new Date(new Date(e).setHours(new Date(e).getHours() + 8)) })) }}
                                                    format="DD/MM/YYYY hh:mm A"
                                                    value={prebook?.from}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="customer" sm={3}> To </Label>
                                            <Col sm={5}>
                                                <DatePicker
                                                    className="rmdp-mobile"
                                                    plugins={[<TimePicker position="bottom" />]}
                                                    mobileLabels={{
                                                        OK: "Accept",
                                                        CANCEL: "Close",
                                                    }}
                                                    minDate={new Date(new Date(prebook?.from).setHours(new Date(prebook?.from).getHours() + 8))}
                                                    onChange={(e) => { setPrebook((prev) => ({ ...prev, to: new Date(e) })) }}
                                                    value={prebook?.to}
                                                    format="DD/MM/YYYY hh:mm A"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="price" sm={3}> Price </Label>
                                            <Col sm={5}>
                                                <Input className='input-theme text-upper input-size-2' type="text" disabled value={prebook?.actualPrice || 0} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center'><Button onClick={handleRegister} className='button-theme'>Book Now</Button></Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ChooseCustomer;