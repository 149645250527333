import { patchApiCall, postApiCall, reloadWindow } from 'gfdu'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Card, CardBody, Col, Container, Input, Row, Table } from 'reactstrap'
import { decrypt, encrypt } from '../../../Helpers/Crypto'
import { useDispatch, useSelector } from 'react-redux'
import { roleListChange } from "../../../Reducer/roleSlice"
import { formatDate, textCapital } from '../../../Helpers/Utils'
import { toast } from 'react-toastify'
import Pagination from '../../../Helpers/Components/Pagination'
import TablePlaceHolder from "../../../Helpers/Components/TablePlaceHolder"
import { contentLoadingChange } from '../../../Reducer/componentSlice'

const Roles = () => {
    const dispatch = useDispatch()
    const role = useSelector((state) => state.role)
    const navigate = useNavigate()
    const [next, setNext] = useState(false)
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState({
        page: 0,
        count: 10
    })
    const setPageNo = (value) => {
        setFilter((prev) => ({ ...prev, page: value }))
    }
    const search = (e) => {
        setFilter((prev) => ({
            ...prev,
            filter: e.target.value
        }))
    }
    useEffect(() => {
        setLoading(false)
        dispatch(contentLoadingChange(false))
        dispatch(roleListChange([]))
        const filterData = {
            ...(filter?.filter && { filter: filter?.filter })
        }
        postApiCall(`/admin/staff/role/list/${filter?.page}?count=${filter?.count}`, encrypt(filterData), (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(roleListChange(decryptedData?.data?.list))
                    setNext(decryptedData?.data?.next)
                } else {
                    dispatch(roleListChange([]))
                    setNext(false)
                }
            } else {
                dispatch(roleListChange([]))
                setNext(false)
            }
            setLoading(true)
            dispatch(contentLoadingChange(false))
        })
    }, [filter])
    
    return (
        <div className="branch">
            <Container fluid>
                <Row className="justify-content-between" >
                    <Col className='text-center'><h3>Roles</h3></Col>
                </Row>
            </Container>
            <Card className="branch-card table-card">
                <CardBody className="table-content">
                    <Row xl={4} className='justify-content-between'>
                        <Col><Input className='search-box' placeholder='Search...' value={filter?.filter} onChange={search} /></Col>
                        <Col className="d-flex justify-content-end">
                            <Button className='button-theme' onClick={() => navigate("/staff/role/register")} >Registration</Button>
                        </Col>
                    </Row>
                    <Table className="table-bg" borderless >
                        <thead>
                            <tr className="border-bottom" >
                                <th> Role Id</th>
                                <th> Name </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    ((role?.roleList && role?.roleList?.length !== 0) ? role?.roleList?.map((value, key) => {
                                        return (
                                            <tr key={key} className={`table-content-row cursor-pointer`} onClick={() => navigate(`/staff/role/${value?.roleId}`)}>
                                                <td>{(value?.roleId || "-")}</td>
                                                <td>{textCapital(value?.roleName) || "-"}</td>
                                             </tr>
                                        )
                                    }) :
                                        <tr className='text-center' ><td colSpan={7}>No Data Found</td></tr>)
                                    :
                                    <TablePlaceHolder count={7} />
                            }
                        </tbody>
                    </Table>
                    <Pagination pageNo={filter?.page} changePage={setPageNo} next={next} />
                </CardBody>
            </Card>
        </div>
    )
}

export default Roles