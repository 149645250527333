import React, { useEffect, useState } from 'react'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledDropdown } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { contentLoadingChange } from '../../Reducer/componentSlice'
import { useNavigate } from 'react-router-dom'
import { getApiCall, postApiCall } from 'gfdu'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { bikeBrandListChange } from "../../Reducer/bikeVehicleSlice"
import TablePlaceHolder from "../../Helpers/Components/TablePlaceHolder";
import Pagination from "../../Helpers/Components/Pagination";
import { toast } from 'react-toastify'

const BikeList = (props) => {
    const dispatch = useDispatch()
    const bikeVehicle = useSelector((state) => state.bikeVehicle)
    const navigate = useNavigate()
    const [next, setNext] = useState(false)
    const [filter, setFilter] = useState({
        page: 0,
        count: 10
    })
    const [logModal, setLogModal] = useState(false)
  const [next1, setNext1] = useState(false)
  const [log, setLog] = useState([])
  const [pageNo1, setPageNo1] = useState(0)
  const [detail, setDetail] = useState({})
  const [view, setView] = useState(0)
  const [chosen, setChosen] = useState()

  const dateFormat = (date) => {
    const createdAt = new Date(date)
    return createdAt?.getDate() + "-" + (createdAt?.getMonth() + 1) + "-" + createdAt?.getFullYear() + " " + createdAt?.getHours() + ":" + createdAt?.getMinutes()
  }

    const search = (e) => {
        setFilter((prev) => ({
            ...prev,
            filter: e.target.value
        }))
    }
    const setPageNo = (value) => {
        setFilter((prev) => ({ ...prev, page: value }))
    }
    const renderTableRows = (data, parentKey = "") => {
        if (typeof data === "object" && data !== null) {
          return Object.entries(data)
            .filter(([_, value]) => value !== null && JSON.stringify(value) !== "{}")
            .map(([key, value], index) => {
              const currentKey = parentKey ? `${parentKey} - ${key}` : key;
              if (typeof value === "object" && value !== null) {
                return (
                  <React.Fragment key={index}>
                    {renderTableRows(value, currentKey)}
                  </React.Fragment>
                );
              }
              return (
                <tr key={index}>
                  <td>{currentKey}</td>
                  <td>{value?.toString() || "N/A"}</td>
                </tr>
              );
            });
        }
        return null;
      };
      useEffect(() => {
        if (chosen?.brandId && logModal) {
          dispatch(contentLoadingChange(true))
          getApiCall(`/admin/vehicle/brand/changes/log/${chosen?.brandId}?pageNo=${pageNo1}`, (response) => {
            if (response?.success) {
              const decryptedData = decrypt(response?.data)
              if (decryptedData?.success) {
                setNext1(decryptedData?.data?.next)
                setLog(decryptedData?.data?.currentPage)
                setDetail(decryptedData?.data?.currentPage?.length ? decryptedData?.data?.currentPage[0] : {})
              } else {
                setNext1(false)
                setLog([])
                setDetail({})
                toast.error(response?.message)
              }
            } else {
              setNext1(false)
              setLog([])
              setDetail({})
            }
            dispatch(contentLoadingChange(false))
          })
        }
      }, [pageNo1, logModal])
    useEffect(() => {
        dispatch(contentLoadingChange(true))
        dispatch(bikeBrandListChange([]))
        const filterData = {
            ...(filter?.filter && { search: filter?.filter }),
            vehicleType: "Bike"
        }
        postApiCall(`/admin/vehicle/brand/list/${filter?.page}`, encrypt(filterData), (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(bikeBrandListChange(decryptedData?.data?.currentPage))
                    setNext(decryptedData?.data?.next)
                } else {
                    dispatch(bikeBrandListChange([]))
                    setNext(false)
                }
            } else {
                dispatch(bikeBrandListChange([]))
                setNext(false)
            }
            dispatch(contentLoadingChange(false))
        })
    }, [filter])
    return (
        <React.Fragment>
            <Modal size='xl' isOpen={logModal} toggle={() => setLogModal(false)}>
        <ModalHeader>{chosen?.brandId}</ModalHeader>
        <ModalBody className=' px-4 text-center'>
          <Row>
            <Col md={7}>
              <Table className="table-bg" borderless >
                <thead>
                  <tr className="border-bottom" >
                    <th>Action</th>
                    <th>Updated By</th>
                    <th>Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    log ? (log && log?.length !== 0 ? log?.map((value, key) => {
                      return (
                        <tr key={key} className={`cursor-pointer-hover ${view === key ? "chosenLog" : ""}`} onClick={() => { setView(key); setDetail(value); setLogModal(true) }} >
                          <td>{value?.action}</td>
                          <td>{value?.userId}</td>
                          <td>{dateFormat(value?.createdAt)}</td>
                        </tr>
                      )
                    }) :
                      <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                      : <TablePlaceHolder count={4} />
                  }
                </tbody>
              </Table>
              <Pagination pageNo={pageNo1} changePage={setPageNo1} next={next1} />
            </Col>
            <Col md={5}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {detail?.previousData ? (
                    renderTableRows(detail.previousData)
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">
                        No previous data available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
            <Row>
                <Col className='text-center text-theme fs-3'>Bike</Col>
            </Row>
            <Row xs={4} className='justify-content-between' >
                <Col><Input className='search-box input-size-2' placeholder='Search...' value={filter?.filter} onChange={search} /></Col>
            </Row>
            <Row xs={5}>
                <Col xs={12}>
                    <Table className="table-bg" borderless >
                        <thead>
                            <tr className="border-bottom text-middle" >
                                <th>S.No</th>
                                <th>Brand Id</th>
                                <th>Brand Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bikeVehicle &&
                                    bikeVehicle?.bikeBrandList?.length !== 0 ?
                                    bikeVehicle?.bikeBrandList?.map((value, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>{i + 1}</td>
                                                <td className='text-center'>{value?.brandId}</td>
                                                <td className='text-center'>{value?.brand}</td>
                                                <td className='text-center'>
                                                    <UncontrolledDropdown className="mx-3" >
                                                        <DropdownToggle className="nav-link not-button cursor-pointer"><i className="fa-solid fa-ellipsis-vertical"></i></DropdownToggle>
                                                        <DropdownMenu container="body" className='dropList'>
                                                            <DropdownItem onClick={() => navigate(`/vehicle/${value?.brandId}`)}>View</DropdownItem>
                                                            <DropdownItem onClick={() => props?.openEdit(value?.brandId)}>Edit</DropdownItem>
                                                            <DropdownItem onClick={() => { setChosen(value); setLogModal(true) }}>Log</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr className='text-center'>
                                        <td colSpan={4}>No Data Found</td>
                                    </tr>
                            }
                        </tbody>
                    </Table>
                    <Pagination pageNo={filter?.page} changePage={setPageNo} next={next} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default BikeList
