import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { APIProvider, ControlPosition, MapControl, AdvancedMarker, Map, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import Config from '../../../Helpers/Config';
import SearchInput from "../../../Partials/MapUtils/SearchInput";
import MapHandler from "../../../Partials/MapUtils/MapHandler";
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { patchApiCall, postApiCall, reloadWindow, reloadWindowToPath } from 'gfdu';
import { encrypt } from '../../../Helpers/Crypto';
import DatePicker from 'react-multi-date-picker';

const CarDevice = () => {
    const { id } = useParams("id");
    const dispatch = useDispatch()
    const [deviceData, setDeviceData] = useState({});
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [disableButton, setButtonDisable] = useState(false)
    const [currentLocation, setCurrentLocation] = useState({ lat: 12.957639, lng: 80.142861 });
    const [markerRef, marker] = useAdvancedMarkerRef();
    const geocoder = window.google ? (window.google.maps ? (window.google.maps.Geocoder ? new window.google.maps.Geocoder() : null) : null) : null;
    const handleChange = (e) => {
        setDeviceData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };
    const fetchAddressDetails = async (lat, lng) => {
        if (geocoder) {
            geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                if (status === "OK" && results[0]) {
                    const addressComponents = results[0].address_components;
                    const addressData = {
                        street: addressComponents.find((c) => c.types.includes("route"))?.long_name || "",
                        locality: addressComponents.find((c) => c.types.includes("sublocality"))?.long_name || "",
                        city: addressComponents.find((c) => c.types.includes("locality"))?.long_name || "",
                        state: addressComponents.find((c) => c.types.includes("administrative_area_level_1"))?.long_name || "",
                        country: addressComponents.find((c) => c.types.includes("country"))?.long_name || "",
                        pincode: addressComponents.find((c) => c.types.includes("postal_code"))?.long_name || "",
                    };
                    setDeviceData((prev) => ({
                        ...prev,
                        lat,
                        lng,
                        ...addressData,
                    }));
                } else {
                    console.error("Geocoding failed: ", status);
                }
            });
        }
    };

    const setInsurance = (e) => {
        setDeviceData((prev) => ({
            ...prev,
            insuranceDate : e
        }));
    }
    const handleMapClick = (e) => {
        const lat = e?.detail?.latLng.lat;
        const lng = e?.detail?.latLng.lng;
        setDeviceData((prev) => ({ ...prev, lat, lng }));
        fetchAddressDetails(lat, lng);
    };
    const handlePlaceSelect = (place) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setSelectedPlace(place);
        setDeviceData((prev) => ({ ...prev, lat, lng }));
        fetchAddressDetails(lat, lng);
    };
    const errorCall = (msg) => {
        setButtonDisable(false)
        dispatch(contentLoadingChange(false))
        return toast.error(msg)
    }
    const registerDevice = () => {
        const date = new Date(deviceData?.insuranceDate)
        const insuranceDate = `${date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()}-${date?.getMonth() < 10 ? "0" + date?.getMonth() : date?.getMonth()}-${date?.getFullYear()}` || ""
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (deviceData?.deviceId === undefined || deviceData?.deviceId?.trim()?.length === 0 || deviceData?.deviceId === null || deviceData?.deviceId === "" || typeof deviceData?.deviceId !== "string") {
            errorCall("Invalid Device Id")
        } else if (deviceData?.startKm === undefined || deviceData?.startKm?.trim()?.length === 0 || deviceData?.startKm === null || deviceData?.startKm === "" || typeof deviceData?.startKm !== "string" || Number(deviceData?.startKm) === NaN) {
            errorCall("Invalid Start Km")
        } else if (insuranceDate === undefined || insuranceDate === null || insuranceDate === "" || !insuranceDate) {
            errorCall("Invalid Insurance Date")
        } else if (deviceData?.lat === undefined || deviceData?.lat === null || deviceData?.lat === "") {
            errorCall("Invalid Latitude")
        } else if (deviceData?.lng === undefined || deviceData?.lng === null || deviceData?.lng === "") {
            errorCall("Invalid Longitude")
        } else if (deviceData?.street === undefined || deviceData?.street?.trim()?.length === 0 || deviceData?.street === null || deviceData?.street === "" || typeof deviceData?.street !== "string") {
            errorCall("Invalid Street")
        } else if (deviceData?.locality === undefined || deviceData?.locality?.trim()?.length === 0 || deviceData?.locality === null || deviceData?.locality === "" || typeof deviceData?.locality !== "string") {
            errorCall("Invalid Locality")
        } else if (deviceData?.city === undefined || deviceData?.city?.trim()?.length === 0 || deviceData?.city === null || deviceData?.city === "" || typeof deviceData?.city !== "string") {
            errorCall("Invalid City")
        } else if (deviceData?.state === undefined || deviceData?.state?.trim()?.length === 0 || deviceData?.state === null || deviceData?.state === "" || typeof deviceData?.state !== "string") {
            errorCall("Invalid State")
        } else if (deviceData?.country === undefined || deviceData?.country?.trim()?.length === 0 || deviceData?.country === null || deviceData?.country === "" || typeof deviceData?.country !== "string") {
            errorCall("Invalid Country")
        } else if (deviceData?.pincode === undefined || deviceData?.pincode?.trim()?.length === 0 || deviceData?.pincode === null || deviceData?.pincode === "" || typeof deviceData?.pincode !== "string") {
            errorCall("Invalid Pincode")
        } else {
            const data = {
                deviceId: deviceData?.deviceId,
                startKm: deviceData?.startKm,
                insuranceDate: insuranceDate,
                lat: String(deviceData?.lat),
                lng: String(deviceData?.lng),
                street: deviceData?.street,
                locality: deviceData?.locality,
                city: deviceData?.city,
                state: deviceData?.state,
                country: deviceData?.country,
                pincode: deviceData?.pincode
            }
            patchApiCall(`/admin/bike/device/${id}`, encrypt(data), (response) => {
                if (response?.success) {
                    toast.success("Device Registered Successfully")
                    setTimeout(()=>{
                        reloadWindowToPath(`/bike/${id}`)
                    },2000)
                } else {
                    errorCall(response?.msg || response?.message)
                }
            })
        }
    }
    useEffect(() => {
        if (selectedPlace) {
            handlePlaceSelect(selectedPlace);
        }
    }, [selectedPlace])

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(function (position) {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            });
        }
    }, []);
    return (
        <React.Fragment>
            <Row className='mx-2 mb-5 justify-content-center'>
                <Col>
                    <Card className='dark-card'>
                        <CardBody>
                            <Row xs={1} sm={3}>
                                <Col xs={1} sm={8} className='verify-map-selection'>
                                    <APIProvider apiKey={Config?.KEYS?.MAP_KEY}>
                                        <Map mapId={"bf51a910020fa25a"} onClick={handleMapClick} defaultZoom={10} defaultCenter={currentLocation} gestureHandling={"cooperative"} mapTypeControl={true} zoomControl={true} disableDefaultUI={true}>
                                            <AdvancedMarker ref={markerRef} position={deviceData?.lat && deviceData?.lng ? { lat: deviceData.lat, lng: deviceData.lng } : undefined} />
                                        </Map>
                                        <MapControl position={ControlPosition.TOP}>
                                            <div className="autocomplete-control my-2">
                                                <SearchInput onPlaceSelect={setSelectedPlace} />
                                            </div>
                                        </MapControl>
                                        <MapHandler place={selectedPlace} marker={marker} />
                                    </APIProvider>
                                </Col>
                                <Col>
                                    <Row xs={1}>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="deviceId" sm={3}>Device ID</Label>
                                                <Col sm={5}><Input id="deviceId" name="deviceId" className='input-theme input-size-2 ' value={deviceData?.deviceId} onChange={handleChange} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="startKm" sm={3}>Start KM</Label>
                                                <Col sm={5}><Input id="startKm" name="startKm" className='input-theme input-size-2 ' value={deviceData?.startKm} onChange={handleChange} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="insuranceDate" sm={3}>Insurance End</Label>
                                                <Col sm={5}><DatePicker className='calender-theme' format='DD-MM-YYYY' inputClass="date-theme" value={deviceData?.insuranceDate} onChange={setInsurance} minDate={new Date()} /></Col>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="lat" sm={3}>Latitude</Label>
                                                <Col sm={5}><Input id="lat" name='lat' className='input-theme input-size-2 ' disabled={true} value={deviceData?.lat} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="lng" sm={3}>Longitude</Label>
                                                <Col sm={5}><Input id="lng" name='lng' className='input-theme input-size-2 ' disabled={true} value={deviceData?.lng} /></Col>
                                            </FormGroup>
                                        </Col> */}
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="street" sm={3}>Street</Label>
                                                <Col sm={5}><Input id="street" name='street' className='input-theme input-size-2 ' disabled={true} value={deviceData?.street} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="locality" sm={3}>Locality</Label>
                                                <Col sm={5}><Input id="locality" name='locality' className='input-theme input-size-2 ' disabled={true} value={deviceData?.locality} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="city" sm={3}>City</Label>
                                                <Col sm={5}><Input id="city" name='city' className='input-theme input-size-2 ' disabled={true} value={deviceData?.city} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="state" sm={3}>State</Label>
                                                <Col sm={5}><Input id="state" name='state' className='input-theme input-size-2 ' disabled={true} value={deviceData?.state} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="country" sm={3}>Country</Label>
                                                <Col sm={5}><Input id="country" name='country' className='input-theme input-size-2 ' disabled={true} value={deviceData?.country} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="pincode" sm={3}>Pincode</Label>
                                                <Col sm={5}><Input id="pincode" name='pincode' className='input-theme input-size-2 ' disabled={true} value={deviceData?.pincode} /></Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className='text-center'><Button className='button-theme' disabled={disableButton} onClick={registerDevice}>Submit</Button></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CarDevice;