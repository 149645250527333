import React, { useEffect, useState } from "react";
import { initializeApiCall, getCookie, getApiCall } from "gfdu";
import { Helmet } from "react-helmet"
import { ToastContainer } from "react-toastify";
import AuthenticateProvider from "./Contexts/AuthenticateProvider";
import UnAuthorized from "./Helpers/Components/UnAuthorized"
import Routers from "./Routers"
import { decrypt } from "./Helpers/Crypto";
import Favicon from "react-favicon";
import Loader from "./Helpers/Components/Loader";
import ErrorPage from "./Helpers/Components/ErrorPage";
import { useDispatch, useSelector } from "react-redux";
import { siteDetailChange } from "./Reducer/siteDetailsSlice";
import ContentLoader from "./Helpers/Components/ContentLoader";

function App() {
  const siteDetails = useSelector((state) => state.siteDetail)?.siteDetail
  const component = useSelector((state) => state.component);
  const dispatch = useDispatch()
  let token = getCookie("token")
  const [auth, setAuth] = useState(true)
  const [loading, setLoading] = useState({
    errorPage: false,
    content: false
  })
  initializeApiCall(
    {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    "http://13.203.12.42:8000",
    // "http://localhost:8000",
    false,
    [
      {
        status_code: "401",
        func: () => setAuth(false)
      }
    ]
  );
  useEffect(() => {
    setAuth(true)
    getApiCall("/admin-auth/site-details", (response) => {
      if (response?.success) {
        const decryptData = decrypt(response?.data)
        dispatch(siteDetailChange(decryptData?.data))
        setLoading({
          errorPage: false,
          content: true
        })
      } else {
        setLoading({
          errorPage: true,
          content: true
        })
      }
    })
  }, [])
  return (
    <React.Fragment>

      {loading?.errorPage ?
        <ErrorPage />
        :
        <>
          <Favicon url={siteDetails?.favIcon} />
          <Helmet>
            <title>{siteDetails?.siteTitle}</title>
          </Helmet>
          {
            auth ?
              <AuthenticateProvider>
                <Routers />
              </AuthenticateProvider>
              :
              <>
                <UnAuthorized />
              </>
          }
        </>
      }
      {component?.contentLoading && <ContentLoader />}

      <ToastContainer theme={"dark"} autoClose={2000} position="top-right" />
    </React.Fragment>
  );
}

export default App;
