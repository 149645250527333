import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import { getApiCall, patchApiCall, reloadWindow } from "gfdu";
import { toast } from "react-toastify";
import { decrypt, encrypt } from "../../../Helpers/Crypto";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";

const Close = () => {
    const { bookingId } = useParams();
    const dispatch = useDispatch()
    const [fineDetails, setFineDetails] = useState([])
    const [disable, setDisable] = useState(false)

    const handleFineDetails = (key, value, index) => {
        setFineDetails(fineDetails?.map((element, i) => {
            if (i === index) {
                element[[key]] = value
            }
            return element
        }))
    }
    const handleClosing = () => {
        dispatch(contentLoadingChange(true));
        setDisable(true)
        const data = fineDetails?.length > 0 ? encrypt({ fineDetails }) : {}
        console.log(data)
        patchApiCall(`/admin/booking/trip/refund/${bookingId}`, data, (response) => {
            dispatch(contentLoadingChange(false))
            if (response?.success) {
                toast.success("Closed Booking")
                setTimeout(() => {
                    reloadWindow()
                }, 1500);
            } else {
                setDisable(false)
                toast.error(response?.message)
            }
        })
    }
    useEffect(() => {
        dispatch(contentLoadingChange(true));
        getApiCall(`/admin/booking/detail/${bookingId}`, (response) => {
            dispatch(contentLoadingChange(false));
            if (response?.success) {
                const decryptedData = decrypt(response?.data)?.data
                setFineDetails(decryptedData?.tripDetails?.fineDetails || [])
            } else {
                toast.error(response?.message)
            }
        })
    }, [])
    return (
        <React.Fragment>
            <Row className="p-3 px-5">
                <Col md={12}>
                    <FormGroup row>
                        <Label sm={3}>Fine Charges</Label>
                        <Col sm={9}>
                            {
                                fineDetails?.length > 0 ?
                                    fineDetails?.map((element, i) => {
                                        return (
                                            <Row className="align-items-center mb-3" key={i}>
                                                <Col sm={6}>
                                                    <Input placeholder="Fine Name" name="fineName" disabled={disable} className='input-theme' onChange={(event) => { handleFineDetails("fineName", event.target.value, i) }} type="text" value={element?.fineName || ""} />
                                                </Col>
                                                <Col sm={6}>
                                                    <Input placeholder="Fine Amount" name="fineCharges" disabled={disable} className='input-theme' onChange={(e) => { if (e.target.value == Number(e.target.value)) handleFineDetails("fineCharges", Number(e.target.value), i) }} type="text" value={element?.fineCharges || ""} />
                                                </Col>
                                            </Row>
                                        )
                                    }) : ""
                            }
                            {
                                ((fineDetails?.length && fineDetails[fineDetails?.length - 1]?.fineName !== "" && fineDetails[fineDetails?.length - 1]?.fineCharges !== 0) || fineDetails?.length === 0) ?
                                    <Col sm={1} className="d-flex align-items-center text-center"><i className='ms-2 bx bx-plus-circle bx-md cursor-pointer' onClick={() => { setFineDetails((prev) => ([...prev, { fineName: '', fineCharges: 0 }])) }}></i></Col>
                                    : ""
                            }
                        </Col>
                    </FormGroup>
                    <Col className="text-center mt-4">
                        <Button className="button-theme" disabled={disable} onClick={() => handleClosing()}>Close Booking</Button>
                    </Col>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Close;