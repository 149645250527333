import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { contentLoadingChange } from '../../Reducer/componentSlice'
import { getApiCall, patchApiCall, postApiCall, reloadWindow, reloadWindowToPath } from "gfdu";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { decrypt, encrypt } from "../../Helpers/Crypto";
import Select from "react-select";
import TablePlaceHolder from "../../Helpers/Components/TablePlaceHolder"
import Pagination from "../../Helpers/Components/Pagination";
import Log from "./Log";

const View = () => {
    const dispatch = useDispatch();
    const { staffId } = useParams()
    const [staff, setStaff] = useState()
    const [editModal, setEditModal] = useState(false)
    const [buttonDisable, setButtonDisable] = useState(false)
    const [updatedStaff, setUpdatedStaff] = useState({})
    const [roles, setRoles] = useState([])
    const [roleDetail, setRoleDetail] = useState();
    const [role, setRole] = useState([])
    const [logModal, setLogModal] = useState(false)
    const [next, setNext] = useState(false)
    const [log, setLog] = useState([])
    const [pageNo, setPageNo] = useState(0)
    const [detail, setDetail] = useState({})
    const [view, setView] = useState(0)

    const dateFormat = (date) => {
        const createdAt = new Date(date)
        return createdAt?.getDate() + "-" + (createdAt?.getMonth() + 1) + "-" + createdAt?.getFullYear() + " " + createdAt?.getHours() + ":" + createdAt?.getMinutes()
    }
    const handleChange = (e) => {
        setUpdatedStaff((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const onSubmit = () => {
        if (!/^[a-zA-Z\s]{2,}$/.test(updatedStaff?.name) || !updatedStaff?.name?.trim()) {
            toast.error("Invalid Name");
            return false;
        } else if (!/^[6-9]{1}[0-9]{9}$/.test(updatedStaff?.phone)) {
            toast.error("Invalid Phone Number");
            return false;
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(updatedStaff?.email)) {
            toast.error("Invalid Email Id");
            return false;
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(updatedStaff?.passwords)) {
            toast.error("Invalid Password")
        } else if (!updatedStaff?.role) {
            toast.error("Chose a role")
        } else {
            dispatch(contentLoadingChange(true))
            setButtonDisable(true)
            patchApiCall(`/admin/staff/update/details/${staffId}`, encrypt({ name: updatedStaff?.name, phone: updatedStaff?.phone, email: updatedStaff?.email, password: updatedStaff?.passwords, roleId: updatedStaff?.role?.value }), (response) => {
                dispatch(contentLoadingChange(false))
                if (response?.success) {
                    setTimeout(() => {
                        reloadWindow()
                    }, 1500);
                } else {
                    setButtonDisable(false)
                    toast.error(response?.message)
                }
            })
        }
    }
    const handleStatus = (value, id) => {
        dispatch(contentLoadingChange(true))
        patchApiCall(`/admin/staff/update/status/${id}?status=${value}`, {}, (response) => {
            if (response?.success) {
                reloadWindow()
                setTimeout(() => {
                    dispatch(contentLoadingChange(false))
                }, 1200);
            } else {
                toast.error(response?.message)
                dispatch(contentLoadingChange(false))
            }
        })
    }
    const renderTableRows = (data, parentKey = "") => {
        if (typeof data === "object" && data !== null) {
            return Object.entries(data)
                .filter(([_, value]) => value !== null && JSON.stringify(value) !== "{}")
                .map(([key, value], index) => {
                    const currentKey = parentKey ? `${parentKey} - ${key}` : key;
                    if (typeof value === "object" && value !== null) {
                        return (
                            <React.Fragment key={index}>
                                {renderTableRows(value, currentKey)}
                            </React.Fragment>
                        );
                    }
                    return (
                        <tr key={index}>
                            <td>{currentKey}</td>
                            <td>{value?.toString() || "N/A"}</td>
                        </tr>
                    );
                });
        }
        return null;
    };
    useEffect(() => {
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/staff/detail/${staffId}`, (response) => {
            if (response?.success) {
                setStaff(decrypt(response?.data)?.data)
                getApiCall(`/admin/staff/role/detail/${decrypt(response?.data)?.data?.roleId}`, (response) => {
                    dispatch(contentLoadingChange(false))
                    if (response?.success) {
                        setRoleDetail(decrypt(response?.data)?.data)
                        setRole(decrypt(response?.data)?.data?.permission)
                    } else {
                        toast.error(response?.message)
                    }
                })
            } else {
                dispatch(contentLoadingChange(false))
                toast.error(response?.message)
            }
        })
        getApiCall(`/admin/staff/roles/list`, (response) => {
            dispatch(contentLoadingChange(false))
            if (response?.success) {
                setRoles(decrypt(response?.data)?.data)
            } else {
                setRoles([])
            }
        })
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/staff/changes/log/${staffId}?pageNo=${pageNo}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setNext(decryptedData?.data?.next)
                    setLog(decryptedData?.data?.currentPage)
                    setDetail(decryptedData?.data?.currentPage?.length ? decryptedData?.data?.currentPage[0] : {})
                } else {
                    setNext(false)
                    toast.error(response?.message)
                }
            }
            dispatch(contentLoadingChange(false))
        })
    }, [pageNo])
    return (
        <React.Fragment>
            <Modal isOpen={editModal} size="xl" toggle={() => setEditModal(false)} >
                <ModalHeader toggle={() => setEditModal(false)}>Satff Update</ModalHeader>
                <ModalBody>
                    <Row className='my-2 flex-column'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="staffId" sm={3}>Staff ID</Label>
                                <Col sm={5}>
                                    <Input disabled id="staffId" name="staffId" autoComplete='off' value={updatedStaff?.staffId || ''} className='input-theme text-upper' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="name" sm={3}>Name</Label>
                                <Col sm={5}>
                                    <Input disabled={buttonDisable} id="name" name="name" autoComplete='off' onChange={handleChange} value={updatedStaff?.name || ''} className='input-theme text-upper' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="phone" sm={3}>Phone</Label>
                                <Col sm={5}>
                                    <Input disabled={buttonDisable} id="phone" name="phone" maxLength={10} autoComplete='off' onChange={(e) => { if (e.target.value == Number(e.target.value)) { handleChange(e) } }} value={updatedStaff?.phone || ''} className='input-theme text-upper' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="email" sm={3}>Email</Label>
                                <Col sm={5}>
                                    <Input disabled={buttonDisable} id="email" name="email" autoComplete='off' onChange={handleChange} value={updatedStaff?.email || ''} className='input-theme text-lower' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="passwords" sm={3}>Password</Label>
                                <Col sm={5}>
                                    <Input disabled={buttonDisable} id="passwords" name="passwords" autoComplete='off' onChange={handleChange} value={updatedStaff?.passwords || ''} className='input-theme' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="role" sm={3}>Roles</Label>
                                <Col sm={5}>
                                    <Select
                                        styles={{
                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                        }}
                                        isDisabled={buttonDisable}
                                        onChange={(e) => setUpdatedStaff(prev => ({ ...prev, role: e }))}
                                        value={updatedStaff?.role}
                                        options={[
                                            ...(roles?.map(val => ({ label: val?.roleName, value: val?.roleId })) || [])
                                        ]} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <div className='text-center my-3'>
                                <Button className='button-theme' disabled={buttonDisable} onClick={onSubmit}>Update</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size='xl' isOpen={logModal} toggle={() => setLogModal(false)}>
                <ModalHeader>{staffId}</ModalHeader>
                <ModalBody className=' px-4 text-center'>
                    <Row>
                        <Col md={7}>
                            <Table className="table-bg" borderless >
                                <thead>
                                    <tr className="border-bottom" >
                                        <th>Action</th>
                                        <th>Updated By</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        log ? (log && log?.length !== 0 ? log?.map((value, key) => {
                                            return (
                                                <tr key={key} className={`cursor-pointer-hover ${view === key ? "chosenLog" : ""}`} onClick={() => { setView(key); setDetail(value); setLogModal(true) }} >
                                                    <td>{value?.action}</td>
                                                    <td>{value?.userId}</td>
                                                    <td>{dateFormat(value?.createdAt)}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                                            : <TablePlaceHolder count={4} />
                                    }
                                </tbody>
                            </Table>
                            <Pagination pageNo={pageNo} changePage={setPageNo} next={next} />
                        </Col>
                        <Col md={5}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail?.previousData ? (
                                        renderTableRows(detail.previousData)
                                    ) : (
                                        <tr>
                                            <td colSpan="2" className="text-center">
                                                No previous data available.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Container fluid>
                <Row className="justify-content-between" >
                    <Col className='text-center'><h3>Staff View</h3></Col>
                </Row>
            </Container>
            <Row className="align-items-center justify-content-center">
                <Col md={12}>
                    <Card className="p-4 px-5 mx-2">
                        <Row className="text-end"><i className='bx bxs-edit-alt gmd-color bx-sm cursor-pointer' onClick={() => { setUpdatedStaff(staff); setUpdatedStaff((prev) => ({ ...prev, role: { label: roles?.filter(val => { if (val?.roleId === staff?.roleId) { return val } })[0]?.roleName, value: roles?.filter(val => { if (val?.roleId === staff?.roleId) { return val } })[0]?.roleId } })); setEditModal(true); }}></i></Row>
                        <Row>
                            <Col>
                                <FormGroup className="align-items-center" row>
                                    <Label sm={4} > Staff ID</Label>
                                    <Col sm={8}>
                                        <Label className="mb-0">{staff?.staffId}</Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="align-items-center" row>
                                    <Label sm={4} > Name </Label>
                                    <Col sm={8}>
                                        <Label className="mb-0">{staff?.name}</Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="align-items-center" row>
                                    <Label sm={4} > Phone </Label>
                                    <Col sm={8}>
                                        <Label className="mb-0">{staff?.phone}</Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="align-items-center" row>
                                    <Label sm={4} > Email </Label>
                                    <Col sm={8}>
                                        <Label className="mb-0">{staff?.email}</Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="align-items-center" row>
                                    <Label sm={4} > Status </Label>
                                    <Col sm={8}>
                                        <Button className='btn-sm' color={`${staff?.status ? "danger" : "success"}`} onClick={() => { handleStatus(!staff?.status, staff?.staffId) }}> {staff?.status ? "Deactive" : "Active"} </Button>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="align-items-center" row>
                                    <Label sm={4} > Log </Label>
                                    <Col sm={8} className="d-flex">
                                        <i className='bx bx-info-circle bx-sm cursor-pointer' onClick={() => { setLogModal(true) }}></i>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="align-items-center" row>
                                    <Label sm={4} > Role ID</Label>
                                    <Col sm={8}>
                                        <Label className="mb-0">{roleDetail?.roleId}</Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="align-items-center" row>
                                    <Label sm={4} > Role Name </Label>
                                    <Col sm={8}>
                                        <Label className="mb-0 text-capitalize">{roleDetail?.roleName}</Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4} > Permissions </Label>
                                    <Col sm={8}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Controllers</th>
                                                    <th colSpan={3} className="text-center">Permissions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    role && role?.length ?
                                                        role?.map((element, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="text-capitalize">
                                                                        {element?.controller}
                                                                    </td>
                                                                    <td> {element?.action && element?.action?.some(element => element === "read") ? <i className='bx bxs-check-circle green' ></i> : <i className='bx bxs-x-circle red'></i>}        Read</td>
                                                                    <td>{element?.action && element?.action?.some(element => element === "write") ? <i className='bx bxs-check-circle green' ></i> : <i className='bx bxs-x-circle red'></i>} Write</td>
                                                                    <td>{element?.action && element?.action?.some(element => element === "update") ? <i className='bx bxs-check-circle green' ></i> : <i className='bx bxs-x-circle red'></i>} Update</td>

                                                                </tr>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Log />
        </React.Fragment>
    )
}

export default View;