import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    roleList : []
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    roleListChange : (state,{payload}) => {
      state.roleList = payload
    }
  }
});

export const { roleListChange } = roleSlice.actions
export default roleSlice.reducer;
