import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import unAuth from "../../Assets/images/401.jpg"
import { reloadWindow, removeCookie, setCookie } from 'gfdu'

const UnAuthorized = () => {
    const login =  () =>{
        removeCookie("token")
        removeCookie("userId")
        // setIsAuthenticated(false);
        reloadWindow()
    }
    return (
        <React.Fragment>
            <Container>
                <Row className=' vh-100 justify-content-center align-items-center' lg={3} >
                    <Col lg={8} className='vh-100'>
                        <div className='my-5 py-5 d-flex flex-column justify-content-center align-items-center'>
                            <h2 className='text-theme' >401 UnAuthorized Access</h2>
                            <Button className='button-theme' onClick={login} >Login Again</Button>
                            <div className='unauth my-3' ><img src={unAuth} alt='404' /></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default UnAuthorized