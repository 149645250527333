import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Input, Row, Table } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { patchApiCall, postApiCall, reloadWindowToPath } from 'gfdu';
import { encrypt } from '../../../Helpers/Crypto';
import { contentLoadingChange } from '../../../Reducer/componentSlice';

const CarVerification = () => {
    const { id } = useParams("id");
    const dispatch = useDispatch();
    const [verificationData, setVerificationData] = useState([
        { perHourKm: "15", perHourPrice: "" },
        { perHourKm: "20", perHourPrice: "" },
        { perHourKm: "25", perHourPrice: "" }
    ]);
    const [disableButton, setButtonDisable] = useState(false);

    const handleChange = (index, field, value) => {
        setVerificationData((prev) =>
            prev.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            )
        );
    };

    const verifyCar = async () => {
        dispatch(contentLoadingChange(true));
        setButtonDisable(true);
        for (let i = 0; i < verificationData.length; i++) {
            const price = verificationData[i]?.perHourPrice;
            if (!price || isNaN(price)) {
                toast.error("Each package price must be a non-empty numeric value.");
                setButtonDisable(false);
                dispatch(contentLoadingChange(false));
                return;
            }
        }
        for (let i = 0; i < verificationData.length - 1; i++) {
            const currentPrice = parseFloat(verificationData[i].perHourPrice);
            const nextPrice = parseFloat(verificationData[i + 1].perHourPrice);
            if (currentPrice > nextPrice) {
                toast.error("Each package price must be lesser than or equal to the next package price.");
                setButtonDisable(false);
                dispatch(contentLoadingChange(false));
                return;
            }
        }
        const encryptedData = encrypt({ packages: verificationData });
        patchApiCall(`/admin/bike/verify/${id}`, encryptedData, (response) => {
            dispatch(contentLoadingChange(false));
            if (response.success) {
                toast.success("Car verified successfully!");
                setTimeout(() => {
                    reloadWindowToPath(`/bike/${id}`)
                }, 2000)
            } else {
                toast.error(response?.message || response?.msg);
                setButtonDisable(false);
            }
        });
    };



    return (
        <React.Fragment>
            <Row className='mx-2 mb-5 justify-content-center'>
                <Col>
                    <Card className='dark-card'>
                        <CardBody>
                            <Row xs={1} sm={3} className='justify-content-center'>
                                <Col >
                                    <Table className="table-bg" borderless>
                                        <thead>
                                            <tr className="border-bottom text-middle">
                                                <th className='text-center'>S.No</th>
                                                <th className='text-center'>KM Per Hour</th>
                                                <th className='text-center'>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {verificationData.map((value, i) => (
                                                <tr key={i + 1}>
                                                    <td className='text-center'>{i + 1}</td>
                                                    <td className='text-center'>{value.perHourKm}</td>
                                                    <td className='text-center'>
                                                        <Input
                                                            name={`price-${i}`}
                                                            className='input-theme'
                                                            value={value.perHourPrice}
                                                            onChange={(e) => {
                                                                if (Number(e.target.value) == e.target.value) { handleChange(i, 'perHourPrice', e.target.value) }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row><Col className='text-center'><Button className='button-theme' onClick={verifyCar} disabled={disableButton}>Verify Car</Button></Col></Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CarVerification;