import { patchApiCall, postApiCall, reloadWindow } from 'gfdu'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Card, CardBody, Col, Container, Input, Row, Table } from 'reactstrap'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { useDispatch, useSelector } from 'react-redux'
import { staffListChange } from "../../Reducer/staffSlice"
import { formatDate, textCapital } from '../../Helpers/Utils'
import { toast } from 'react-toastify'
import Pagination from '../../Helpers/Components/Pagination'
import TablePlaceHolder from "../../Helpers/Components/TablePlaceHolder"
import { contentLoadingChange } from '../../Reducer/componentSlice'

const StaffList = () => {
    const dispatch = useDispatch()
    const staff = useSelector((state) => state.staff)
    const navigate = useNavigate()
    const [next, setNext] = useState(false)
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(1)
    const [filter, setFilter] = useState({
        page: 0,
        count: 10,
        sortBy: "staffId",
        sort: false
    })
    const sort = (sortBy, sort) => {
        setFilter((prev) => ({
            ...prev,
            sortBy: sortBy,
            sort: sort
        }))
    }
    const setPageNo = (value) => {
        setFilter((prev) => ({ ...prev, page: value }))
    }
    const search = (e) => {
        setFilter((prev) => ({
            ...prev,
            filter: e.target.value
        }))
    }
    useEffect(() => {
        setLoading(false)
        dispatch(contentLoadingChange(false))
        dispatch(staffListChange([]))
        const filterData = {
            ...(filter?.filter && { filter: filter?.filter }),
            ...(filter?.status && { status: filter?.status }),
            sort: filter?.sort,
            sortBy: filter?.sortBy,
            ...(status !== 1 ? { status: status === 2 ? true : false } : {}),
        }
        postApiCall(`/admin/staff/list/${filter?.page}?count=${filter?.count}`, encrypt(filterData), (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(staffListChange(decryptedData?.data?.list))
                    setNext(decryptedData?.data?.next)
                } else {
                    dispatch(staffListChange([]))
                    setNext(false)
                }
            } else {
                dispatch(staffListChange([]))
                setNext(false)
            }
            setLoading(true)
            dispatch(contentLoadingChange(false))
        })
    }, [filter, status])

    return (
        <div className="branch">
            <Container fluid>
                <Row className="justify-content-between" >
                    <Col className='text-center'><h3>Staff</h3></Col>
                </Row>
            </Container>
            <Card className="branch-card table-card">
                <CardBody className="table-content">
                    <Row xl={4} className='justify-content-between'>
                        <Col><Input className='search-box' placeholder='Search...' value={filter?.filter} onChange={search} /></Col>
                        <Col className='text-center'>
                            <ButtonGroup className='table-button' size="sm">
                                <Button outline className={status === 1 ? "t-btn-s" : ""} onClick={() => setStatus(1)} >All</Button>
                                <Button outline className={status === 2 ? "t-btn-s" : ""} onClick={() => setStatus(2)}>Active</Button>
                                <Button outline className={status === 3 ? "t-btn-s" : ""} onClick={() => setStatus(3)}>Deactive</Button>
                            </ButtonGroup>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button className='button-theme me-2' onClick={() => navigate("/staff/role")} >Role</Button>
                            <Button className='button-theme' onClick={() => navigate("/staff/registration")} >Registration</Button>
                        </Col>
                    </Row>
                    <Table className="table-bg" borderless >
                        <thead>
                            <tr className="border-bottom" >
                                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "staffId" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("staffId", false)} >▲</i><i className={filter?.sortBy === "staffId" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("staffId", true)} >▼</i></div> Staff Id</div></th>
                                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "name" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("name", false)} >▲</i><i className={filter?.sortBy === "name" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("name", true)} >▼</i></div> Name </div></th>
                                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "phone" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("phone", false)} >▲</i><i className={filter?.sortBy === "phone" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("phone", true)} >▼</i></div> Phone </div></th>
                                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "email" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("email", false)} >▲</i><i className={filter?.sortBy === "email" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("email", true)} >▼</i></div> Email </div></th>
                                <th>Role Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    ((staff?.staffList && staff?.staffList?.length !== 0) ? staff?.staffList?.map((value, key) => {
                                        return (
                                            <tr key={key} className={`cursor-pointer-hover table-content-row`} onClick={() => navigate(`/staff/${value?.staffId}`)} >
                                                <td>{(value?.staffId || "-")}</td>
                                                <td>{textCapital(value?.name) || "-"}</td>
                                                <td>{value?.phone || "-"}</td>
                                                <td>{value?.email || "-"}</td>
                                                <td className='text-capitalize'>{value?.roleName || "-"}</td>
                                                <td>{value?.status ? "Active" : "Deactive"}</td>
                                            </tr>
                                        )
                                    }) :
                                        <tr className='text-center' ><td colSpan={7}>No Data Found</td></tr>)
                                    :
                                    <TablePlaceHolder count={7} />
                            }
                        </tbody>
                    </Table>
                    <Pagination pageNo={filter?.page} changePage={setPageNo} next={next} />
                </CardBody>
            </Card>
        </div>
    )
}

export default StaffList