import { getApiCall, postApiCall } from "gfdu";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Badge, Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { decrypt, encrypt } from "../../../Helpers/Crypto";
import Config from "../../../Helpers/Config";
import { toast } from "react-toastify";
import { useAuth } from "../../../Contexts/AuthenticateProvider";
import PlaceInput from "../../../Helpers/Components/PlaceInput";
import moment from 'moment'
import { useDispatch } from "react-redux";
import { contentLoadingChange } from '../../../Reducer/componentSlice';

const CarView = () => {
    const { carId } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const { siteDetails, userDetails } = useAuth()
    const [car, setCar] = useState();
    const [img, setImg] = useState();
    const dispatch = useDispatch();
    const featureIcons = [
        { icon: <i className="bi bi-luggage"></i>, value: "Luggage" },
        { icon: <i className="bi bi-battery-charging"></i>, value: "Mobile Charger" },
        { icon: <i className='bx bxs-baby-carriage' ></i>, value: "Baby Seat" },
        { icon: <i className="bi bi-tools"></i>, value: "Puncture Kit" },
        { icon: <i className='bx bxs-spray-can' ></i>, value: "Air Freshener" },
        { icon: <i className='bx bx-user-voice' ></i>, value: "Voice Control" },
        { icon: <i className='bx bx-camera-home' ></i>, value: "Reverse Camera" },
        { icon: <i className="bi bi-broadcast"></i>, value: "Reverse Sensor" },
        { icon: <i className='bx bx-bluetooth' ></i>, value: "Bluetooth" },
        { icon: <i className='bx bx-wind'></i>, value: "Ac" },
        { icon: <i className='bx bxs-music' ></i>, value: "Music System" },
        { icon: <i className="bi bi-arrow-repeat"></i>, value: "Power Steering" },
        { icon: <i className="bi bi-person-video3"></i>, value: "Power Window" },
        { icon: <i className='bx bxs-car-mechanic'></i>, value: "Spare Tyre" },
        { icon: <i className='bx bx-shield'></i>, value: "Air Bag" },
        { icon: <i className="bi bi-earbuds"></i>, value: "Aux Input" },
        { icon: <i className="bi bi-distribute-horizontal"></i>, value: "Boot Space" }
    ]
    const [hours, sethours] = useState()
    const [bookingData, setBookingData] = useState({
        carId: carId,
        startDate: searchParams.get("startDate"),
        endDate: searchParams.get('endDate'),
        package: searchParams.get("package"),
    });

    useEffect(() => {
        if (bookingData?.startDate && bookingData?.endDate) {
            let start = moment(Number(bookingData?.startDate));
            let end = moment(Number(bookingData?.endDate));
            let diff = end.diff(start);
            let hours = Math.abs(diff / (1000 * 60 * 60));
            sethours(Math.ceil(hours))
        }
    }, [bookingData])

    const getCar = () => {
        if (carId) {
            dispatch(contentLoadingChange(true))
            getApiCall(`/admin/booking/car/detail/${carId}`, (response) => {
                if (response?.success) {
                    setCar((prev) => ({ ...prev, ...decrypt(response?.data)?.data }))
                } else {
                    toast.error(response?.message)
                } dispatch(contentLoadingChange(false))
            });
        }

    }

    // const bookNow = () => {
    //     if (!bookingData?.carId) {
    //         return
    //     } else if (!bookingData?.startDate) {
    //         toast?.error('Invalid Start Date')
    //     } else if (!bookingData?.endDate) {
    //         toast?.error('Invalid End Date')
    //     } else if (!bookingData?.deliveryDetails?.latitude || !bookingData?.deliveryDetails?.longitude) {
    //         toast?.error('Invalid Delivery Location')
    //     } else if (!pickup?.latitude || !pickup?.longitude) {
    //         toast?.error('Invalid Pickup Location')
    //     } else if (!bookingData?.package) {
    //         toast?.error('Invalid Package')
    //     } else {
    //         postApiCall("/admin/booking/prebook", encrypt(bookingData), (response) => {
    //             if (response?.success) {
    //                 paymentComplete(decrypt(response?.data)?.data)
    //             } else {
    //                 toast.error(response?.msg || response?.message || "Booking Failed")
    //             }
    //         })
    //     }
    // }

    useEffect(() => {
        getCar()
    }, [])

    return (
        <React.Fragment>
            <Container>
                <Row className="mt-3">
                    <Col xs={12} lg={7}>
                        <div className="carBig">
                            <img className="car-view-big" src={!img ? car?.carImages[0] : img} alt="images" />
                        </div>
                        <Row className="all-images row">
                            {car && car?.carImages?.map((image, i) => {
                                return (
                                    <Col xs={12} md={4} key={i} className="my-2">
                                        <img onClick={() => setImg(image)} className="all-car" src={image} alt="images" />
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                    <Col xs={12} lg={5}>
                        <Row className="car-view-details justify-content-between">
                            <h4 className="fw-bold mb-4">{car?.year + " " + car?.brand + " " + car?.modelName}</h4>
                            <Col xs={6} md={4}> {car?.fuelType} </Col>
                            <Col xs={6} md={4}> {car?.transmissionType} </Col>
                            <Col xs={6} md={4}> {car?.seats + " " + "Seater"} </Col>
                            <div className="mt-3 mb-1">
                                <i className="bi bi-geo-alt-fill gmd-color me-2"></i>
                                {car?.location?.street + " , " + car?.location?.locality + " ," + car?.location?.city + " , "}
                            </div>
                            <div className="mb-3">
                                {car?.location?.state + " , " + car?.location?.country + " , " + car?.location?.pincode}
                            </div>
                            <div className="my-3">
                                <span className="affordable">Highly affordable &amp; realiable</span>
                            </div>
                            <Col xs={4}>
                                <h4 className="mt-4">Total Price</h4>
                                <p>Inclusive of Taxes</p>
                            </Col>
                            <Col xs={4}>
                                <h4 className="mt-4 fw-bold">₹ {Number(hours) * Number(car?.priceTypes[bookingData?.package?.split(" ")[1] - 1]?.perHourPrice)}</h4>
                                <p>Inclusive of Taxes</p>
                            </Col>
                            <Col xs={12} className="text-center">
                                <Button className="button-theme"  onClick={()=>navigate(`/booking/prebook/${carId}?startDate=${bookingData?.startDate}&endDate=${bookingData?.endDate}&package=${bookingData?.package}`)}>Book Now</Button>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <h5 className="fw-bold mt-5 mb-3">Features</h5>
                            {car?.features?.map((feature, i) => {
                                return (
                                    <Col xs={12} md={6} lg={4} key={i}>
                                        {featureIcons?.map((value, i) => {
                                            return (
                                                value?.value == feature ?
                                                    <div key={i} className="d-flex align-items-center"><span className="me-2 feature-icon">{value?.icon}</span>{value?.value}</div>
                                                    : ""
                                            )
                                        })}
                                    </Col>
                                )
                            })}
                            <h5 className="fw-bold mt-5 mb-3">Specification</h5>
                            <Col xs={12} md={6} className="px-2 mt-2">
                                <Badge className="w-100">Seats : {car?.seats + " Seater"}</Badge>
                            </Col>
                            <Col xs={12} md={6} className="px-2 mt-2">
                                <Badge className="w-100">Transmission Type : {car?.transmissionType}</Badge>
                            </Col>
                            <Col xs={12} md={6} className="px-2 mt-2">
                                <Badge className="w-100">Variant Name : {car?.variantName}</Badge>
                            </Col>
                            <h5 className="fw-bold mt-5 mb-3">Car Location</h5>
                            <div className="mt-3 mb-1">
                                <i className="bi bi-geo-alt-fill gmd-color me-2"></i>
                                {car?.location?.street + " , " + car?.location?.locality + " ," + car?.location?.city + " , "}
                            </div>
                            <div className="mb-3">
                                {car?.location?.state + " , " + car?.location?.country + " , " + car?.location?.pincode}
                            </div>
                        </Row>
                    </Col>
                </Row> 
            </Container>
        </React.Fragment>
    )
}

export default CarView;