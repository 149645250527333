import { postApiCall, textCapitalize } from 'gfdu'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, Table, UncontrolledDropdown } from 'reactstrap'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { useDispatch, useSelector } from 'react-redux'
import { associateListChange } from "../../Reducer/associateSlice"
import { formatDate, textCapital } from '../../Helpers/Utils'
import { contentLoadingChange } from "../../Reducer/componentSlice"
import Pagination from '../../Helpers/Components/Pagination'
import TablePlaceHolder from '../../Helpers/Components/TablePlaceHolder'

const AssociateList = () => {
  const dispatch = useDispatch()
  const associate = useSelector((state) => state.associate)
  const navigate = useNavigate()
  const [next, setNext] = useState(false)
  const [loading, setLoading] = useState(true)
  const [createdBy, setCreatedBy] = useState(1)
  const [status, setStatus] = useState(1)
  const [filter, setFilter] = useState({
    page: 0,
    count: 10,
    // filter: "",
    // status: true,
    // verified: false,
    // createdBy: "",
    sortBy: "associateId",
    sort: false
  })
  const sort = (sortBy, sort) => {
    setFilter((prev) => ({
      ...prev,
      sortBy: sortBy,
      sort: sort
    }))
  }
  const setPageNo = (value) => {
    setFilter((prev) => ({ ...prev, page: value }))
  }
  const search = (e) => {
    setFilter((prev) => ({
      ...prev,
      filter: e.target.value
    }))
  }
  useEffect(() => {
    setLoading(false)
    dispatch(associateListChange([]))
    dispatch(contentLoadingChange(true))
    const filterData = {
      ...(filter?.filter && { filter: filter?.filter }),
      ...(filter?.status && { status: filter?.status }),
      ...(filter?.createdBy && { createdBy: filter?.createdBy }),
      sort: filter?.sort,
      sortBy: filter?.sortBy,
      ...(status !== 1 ? { status: status === 2 ? true : false } : {}),
      ...(createdBy !== 1 ? { createdBy: createdBy === 2 ? "admin" : createdBy === 3 ? "subadmin" : "self" } : {}),
    }
    postApiCall(`/admin/associate/list/${filter?.page}?count=${filter?.count}`, encrypt(filterData), (response) => {
      if (response?.success) {
        const decryptedData = decrypt(response?.data)
        if (decryptedData?.success) {
          dispatch(associateListChange(decryptedData?.data?.list))
          setNext(decryptedData?.data?.next)
        } else {
          dispatch(associateListChange([]))
          setNext(false)
        }
      } else {
        dispatch(associateListChange([]))
        setNext(false)
      }
      setLoading(true)
      dispatch(contentLoadingChange(false))
    })
  }, [filter, status, createdBy])
  return (
    <div className="branch">
      <Container fluid>
        <Row xs={1} >
          <Col className='text-center text-theme'><h3>Associate</h3></Col>
          <Col >
            <Card className="branch-card table-card">
              <CardBody className="table-content" >
                <Row xs={4} >
                  <Col><Input className='search-box' placeholder='Search...' value={filter?.filter} onChange={search} /></Col>
                  <Col className='text-center'>
                    <ButtonGroup className='table-button' size="sm">
                      <Button outline className={status === 1 ? "t-btn-s" : ""} onClick={() => setStatus(1)} >All</Button>
                      <Button outline className={status === 2 ? "t-btn-s" : ""} onClick={() => setStatus(2)}>Active</Button>
                      <Button outline className={status === 3 ? "t-btn-s" : ""} onClick={() => setStatus(3)}>Deactive</Button>
                    </ButtonGroup>
                  </Col>
                  <Col className='text-center'>
                    <ButtonGroup className='table-button' size="sm">
                      <Button outline className={createdBy === 1 ? "t-btn-s" : ""} onClick={() => setCreatedBy(1)} >All</Button>
                      <Button outline className={createdBy === 2 ? "t-btn-s" : ""} onClick={() => setCreatedBy(2)}>Admin</Button>
                      <Button outline className={createdBy === 3 ? "t-btn-s" : ""} onClick={() => setCreatedBy(3)}>Staff</Button>
                      <Button outline className={createdBy === 4 ? "t-btn-s" : ""} onClick={() => setCreatedBy(4)}>Self</Button>
                    </ButtonGroup>
                  </Col>
                  <Col><div className="d-flex justify-content-end"><Button className='button-theme' onClick={() => navigate("/associate/registration")} >Registration</Button></div></Col>
                </Row>
                <Table className="table-bg" borderless >
                  <thead>
                    <tr className="border-bottom" >
                      <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "associateId" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("associateId", false)} >▲</i><i className={filter?.sortBy === "associateId" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("associateId", true)} >▼</i></div> Associate Id</div></th>
                      <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "name" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("name", false)} >▲</i><i className={filter?.sortBy === "name" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("name", true)} >▼</i></div> Name </div></th>
                      <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "phone" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("phone", false)} >▲</i><i className={filter?.sortBy === "phone" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("phone", true)} >▼</i></div> Phone </div></th>
                      <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "email" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("email", false)} >▲</i><i className={filter?.sortBy === "email" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("email", true)} >▼</i></div> Email </div></th>
                      <th>Gender</th>
                      <th>Created At</th>
                      <th>Created By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ?
                      (associate?.associateList && associate?.associateList?.length !== 0 ? associate?.associateList?.map((value, key) => {
                        return (
                          <tr key={key} className={`table-content-row ${!value?.status && "text-disable"}`} >
                            <td>{(value?.associateId)?.toUpperCase()}</td>
                            <td>{textCapital(value?.name)}</td>
                            <td>{value?.phone}</td>
                            <td>{value?.email?.emailId}</td>
                            <td>{textCapitalize(value?.gender)}</td>
                            <td>{formatDate(value?.createdAt)}</td>
                            <td>{textCapitalize(value?.createdBy)}</td>
                            <td className='text-center'>
                              <UncontrolledDropdown className="mx-3" >
                                <DropdownToggle className="nav-link not-button cursor-pointer"><i className="fa-solid fa-ellipsis-vertical"></i></DropdownToggle>
                                <DropdownMenu container="body" className='dropList'>
                                  <DropdownItem onClick={() => navigate(`/associate/${value?.associateId}`)}>View</DropdownItem>
                                  <DropdownItem onClick={() => { navigate(`/associate/log/${value?.associateId}`)}}>Log</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        )
                      }) :
                        <tr className='text-center' ><td colSpan={7}>No Data Found</td></tr>)
                      : <TablePlaceHolder count={7} />
                    }
                  </tbody>
                </Table>
                <Pagination pageNo={filter?.page} changePage={setPageNo} next={next} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AssociateList