import { getApiCall } from 'gfdu';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { decrypt } from '../../Helpers/Crypto';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { textCapital } from '../../Helpers/Utils';
import TablePlaceHolder from "../../Helpers/Components/TablePlaceHolder";
import Pagination from "../../Helpers/Components/Pagination";
import { contentLoadingChange } from '../../Reducer/componentSlice'
import { useDispatch } from 'react-redux';

const ModelView = () => {
    const { id } = useParams("id");
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [modelDetails, setModelDetails] = useState({})
    const [openEdit, setOpenEditModel] = useState(false)
    const [loading, setLoading] = useState(true)
    const [logModal, setLogModal] = useState(false)
    const [next1, setNext1] = useState(false)
    const [log, setLog] = useState([])
    const [pageNo1, setPageNo1] = useState(0)
    const [detail, setDetail] = useState({})
    const [view, setView] = useState(0)
    const [chosen, setChosen] = useState()

    const dateFormat = (date) => {
        const createdAt = new Date(date)
        return createdAt?.getDate() + "-" + (createdAt?.getMonth() + 1) + "-" + createdAt?.getFullYear() + " " + createdAt?.getHours() + ":" + createdAt?.getMinutes()
    }
    const renderTableRows = (data, parentKey = "") => {
        if (typeof data === "object" && data !== null) {
          return Object.entries(data)
            .filter(([_, value]) => value !== null && JSON.stringify(value) !== "{}")
            .map(([key, value], index) => {
              const currentKey = parentKey ? `${parentKey} - ${key}` : key;
              if (typeof value === "object" && value !== null) {
                return (
                  <React.Fragment key={index}>
                    {renderTableRows(value, currentKey)}
                  </React.Fragment>
                );
              }
              return (
                <tr key={index}>
                  <td>{currentKey}</td>
                  <td>{value?.toString() || "N/A"}</td>
                </tr>
              );
            });
        }
        return null;
      };
      useEffect(() => {
        if (chosen?.brandId && logModal) {
          dispatch(contentLoadingChange(true))
          getApiCall(`/admin/vehicle/variant/changes/log/${chosen?.variantId}?pageNo=${pageNo1}`, (response) => {
            if (response?.success) {
              const decryptedData = decrypt(response?.data)
              if (decryptedData?.success) {
                setNext1(decryptedData?.data?.next)
                setLog(decryptedData?.data?.currentPage)
                setDetail(decryptedData?.data?.currentPage?.length ? decryptedData?.data?.currentPage[0] : {})
              } else {
                setNext1(false)
                setLog([])
                setDetail({})
                toast.error(response?.message)
              }
            } else {
              setNext1(false)
              setLog([])
              setDetail({})
            }
            dispatch(contentLoadingChange(false))
          })
        }
      }, [pageNo1, logModal])
    useEffect(() => {
        setModelDetails({})
        setLoading(true)
        getApiCall(`/admin/vehicle/brand/model/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setModelDetails((prev) => ({
                        ...prev,
                        ...decryptedData?.data
                    }))

                } else {
                    setModelDetails({})
                }
            } else {
                setModelDetails({})
                toast.error("Unable to Retrive Data")
            }
            setLoading(false)
        })
    }, [])
    return (

        <React.Fragment>
            <Modal size='xl' isOpen={logModal} toggle={() => setLogModal(false)}>
        <ModalHeader>{chosen?.variantId}</ModalHeader>
        <ModalBody className=' px-4 text-center'>
          <Row>
            <Col md={7}>
              <Table className="table-bg" borderless >
                <thead>
                  <tr className="border-bottom" >
                    <th>Action</th>
                    <th>Updated By</th>
                    <th>Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    log ? (log && log?.length !== 0 ? log?.map((value, key) => {
                      return (
                        <tr key={key} className={`cursor-pointer-hover ${view === key ? "chosenLog" : ""}`} onClick={() => { setView(key); setDetail(value); setLogModal(true) }} >
                          <td>{value?.action}</td>
                          <td>{value?.userId}</td>
                          <td>{dateFormat(value?.createdAt)}</td>
                        </tr>
                      )
                    }) :
                      <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                      : <TablePlaceHolder count={4} />
                  }
                </tbody>
              </Table>
              <Pagination pageNo={pageNo1} changePage={setPageNo1} next={next1} />
            </Col>
            <Col md={5}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {detail?.previousData ? (
                    renderTableRows(detail.previousData)
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">
                        No previous data available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
            {
                loading ?
                    ""
                    :
                    <React.Fragment>
                        <Row xs={1}>
                            <Col className='text-center text-theme fs-3'>Model Details</Col>
                            <Col className='my-2'>
                                <Card>
                                    <CardBody>
                                        <Row sm={3} xs={1}>
                                            <Col sm={3}>
                                                <Table className='table-pad mx-3'>
                                                    <tr>
                                                        <td>Brand ID</td>
                                                        <td>{modelDetails?.brandId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Brand Name</td>
                                                        <td>{modelDetails?.brand}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Model ID</td>
                                                        <td>{modelDetails?.modelId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Model Name</td>
                                                        <td>{textCapital(modelDetails?.modelName)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Segment</td>
                                                        <td>{modelDetails?.segment}</td>
                                                    </tr>
                                                </Table>
                                                <div className='text-center'><Button className='button-theme' onClick={() => navigate(`/vehicle/model/variant/${modelDetails?.modelId}`)}>New Variant</Button></div>
                                            </Col>
                                            <Col sm={9} className='col-scroll'>
                                                <Table className="table-bg table-scroll my-2" borderless>
                                                    <thead>
                                                        <tr className="border-bottom text-center">
                                                            <th>S.No</th>
                                                            <th>Variant Name</th>
                                                            <th>Transmisson</th>
                                                            <th>Fuel Type</th>
                                                            <th>Max Kms Range</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            modelDetails?.variants && modelDetails?.variants?.length !== 0 ? modelDetails?.variants?.map((value, key) => {
                                                                return (
                                                                    <tr key={key} className={`text-center ${!value?.status ? "text-red" : ""}`} >
                                                                        <td>{key + 1}</td>
                                                                        <td>{textCapital(value?.variantName)}</td>
                                                                        <td>{value?.transmissionType || ""}</td>
                                                                        <td>{value?.fuelType || ""}</td>
                                                                        <td>{`${value?.seats} Seats` || ""}</td>
                                                                        <td className='d-flex w-100 justify-content-center'>
                                                                            <UncontrolledDropdown className="mx-3" >
                                                                                <DropdownToggle className="nav-link not-button"><i className="fa-solid fa-ellipsis-vertical cursor-pointer "></i></DropdownToggle>
                                                                                <DropdownMenu container="body" className='dropList'>
                                                                                    <DropdownItem onClick={() => navigate(`/vehicle/variant/${value?.variantId}`)}>View</DropdownItem>
                                                                                    <DropdownItem onClick={() => navigate(`/vehicle/model/variant/edit/${value?.variantId}`)}>Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => { setChosen(value); setLogModal(true) }}>Log</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) :
                                                                <tr><td className='text-center' colSpan={9}>No Data Found</td></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ModelView

