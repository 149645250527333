import React, { useEffect, useState } from "react";
import { Col, Container, Input, Row, InputGroup, InputGroupText, Accordion, AccordionBody, AccordionHeader, AccordionItem, FormGroup, Label, Card, Button } from "reactstrap";
// import Line from '../Assets/images/line.svg'
import Select from "react-select";
import { postApiCall } from "gfdu";
import { decrypt, encrypt } from "../../../Helpers/Crypto";
import { Link, useNavigate } from "react-router-dom";
import PlaceInput from "../../../Helpers/Components/PlaceInput";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import { useDispatch } from "react-redux";
import { contentLoadingChange } from '../../../Reducer/componentSlice';

const Registration = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState('');
    const [filtered, setFiltered] = useState({ startDate: new Date(new Date().setHours(new Date().getHours() + 2)), endDate: new Date(new Date().setHours(new Date().getHours() + 8)), lat: '', lng: '', radius: '10', segment: [], fuelType: [], transmissionType: [], seats: [], priceType: '' });
    const [carList, setCarList] = useState([]);

    const filter = {
        segment: ["Hatchback", "Sedan", "SUV", "Electric Cars", "Luxury Cars", "Crossovers"],
        fuelType: ["Petrol", "Diesel", "Bio-Diesel", "CNG", "LPG", "EV"],
        transmission: ["Gear", "Automatic", 'Semi-Auto'],
        seats: ['4', '5', '6', '7', '8', '9'],
        fastag: { fastag: false },
        distance: ['10', '20', 'All'],
        sort: { label: '', value: '' },
        priceType: ["15", "20", "25"]
    }
    const priceFilter = [
        { label: 'Low to High', value: true },
        { label: 'High to Low', value: false }
    ]
    const setLocation = (value) => {
        setFiltered((prev) => ({
            ...prev,
            lat: value?.lat,
            lng: value?.lng
        }))
    };
    let diff = (new Date(filtered?.endDate).getTime() - new Date(filtered?.startDate).getTime()) / 1000;
    diff /= (60 * 60);
    let hours = Math.abs(Math.round(diff));
    const handleSelectChange = (e, name) => {
        setFiltered((prev) => ({
            ...prev,
            [name]: e
        }))
    }
    const radioOnchange = (e) => {
        setFiltered((pre) => ({
            ...pre,
            [e?.target?.name]: e.target?.value
        }))
    }
    const checkOnchange = (e) => {
        const checkData = filtered?.[e?.target?.name]?.includes(e.target.value)
        if (!checkData) {
            setFiltered((pre) => ({
                ...pre,
                [e?.target?.name]: [...(pre?.[e?.target?.name] || []), e.target?.value]
            }))
        } else {
            setFiltered((pre) => ({
                ...pre,
                [e?.target?.name]: [...((pre?.[e?.target?.name]?.filter((val) => val !== e.target?.value)) || [])]
            }))
        }
    }
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const getCarList = () => {
        dispatch(contentLoadingChange(true))
        let data = { startDate: filtered?.startDate, endDate: filtered?.endDate, lat: '12.443178', lng: '79.833923', radius: filtered?.radius.toLocaleLowerCase(), ...(filtered?.fuelType?.length !== 0 && { fuelType: filtered?.fuelType }), ...(filtered?.segment?.length !== 0 && { segment: filtered?.segment }), ...(filtered?.seats?.length !== 0 && { seats: filtered?.seats }), ...(filtered?.transmissionType?.length !== 0 && { transmissionType: filtered?.transmissionType }), ...(filtered?.sort?.length !== 0 && { sort: filtered?.sort?.value }) }
        setCarList([])
        postApiCall('/admin/booking/filter/list', encrypt(data), (response) => {

            dispatch(contentLoadingChange(false))
            if (response?.success) {
                setCarList((prev) => ([...prev, ...decrypt(response?.data)?.data]))
            }
        });
    }

    useEffect(() => {
        setFiltered((prev) => ({
            ...prev,
            radius: "All"
        }));
        setFiltered((prev) => ({
            ...prev,
            priceType: "15"
        }));
        setFiltered((prev) => ({
            ...prev,
            sort: false
        }));
        getCarList()
    }, []);
    useEffect(() => {
        getCarList();
    }, [filtered?.radius, filtered?.fuelType, filtered?.segment, filtered?.transmissionType, filtered?.seats, filtered?.endDate, filtered?.startDate, filtered?.lat, filtered?.lng, filtered?.priceType, filtered?.sort]);

    return (
        <React.Fragment>
            <section className="car-list">
                <Container fluid>
                    <Row>
                        <Col xs={2}>
                            <div className="filter-section">
                                <h5>Filter</h5>
                                <Accordion open={open} toggle={toggle}>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">Distance</AccordionHeader>
                                        <AccordionBody accordionId="1">
                                            {
                                                filter && filter?.distance?.map((value, index) => {

                                                    return (
                                                        <FormGroup key={index} check className="ms-2 mt-2 mb-2 d-flex align-items-center">
                                                            <Input className="mt-0 me-2" checked={filtered?.radius === (value)} onChange={radioOnchange} type="radio" id={value} name={'radius'} value={value} />
                                                            <Label for={value} check>
                                                                {value}
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                })
                                            }
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="4">Car Details</AccordionHeader>
                                        <AccordionBody accordionId="4">
                                            <h6 className="filter-title mb-2">Filter By Car Type</h6>
                                            {
                                                filter && filter?.segment?.map((value, index) => {
                                                    return (
                                                        <FormGroup key={index} check className="ms-2 mt-2 mb-2 d-flex align-items-center">
                                                            <Input className="mt-0 me-2" type="checkbox" checked={filtered?.segment.includes(value)} onChange={checkOnchange} id={value} name={'segment'} value={value} />
                                                            <Label for={value} check>{value}</Label>
                                                        </FormGroup>
                                                    )
                                                })
                                            }
                                            <h6 className="filter-title mb-2">Filter By Transmission Type</h6>
                                            {
                                                filter && filter?.transmission?.map((value, index) => {
                                                    return (
                                                        <FormGroup key={index} check className="ms-2 mt-2 mb-2 d-flex align-items-center">
                                                            <Input className="mt-0 me-2" checked={filtered?.transmissionType?.includes(value)} onChange={checkOnchange} type="checkbox" id={value} name={'transmissionType'} value={value} />
                                                            <Label for={value} check>
                                                                {value}
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                })
                                            }
                                            <h6 className="filter-title mb-2">Filter By Fuel Type</h6>
                                            {
                                                filter && filter?.fuelType?.map((value, index) => {
                                                    return (
                                                        <FormGroup key={index} check className="ms-2 mt-2 mb-2 d-flex align-items-center">
                                                            <Input className="mt-0 me-2" checked={filtered?.fuelType?.includes(value)} onChange={checkOnchange} type="checkbox" id={value} name={'fuelType'} value={value} />
                                                            <Label for={value} check>
                                                                {value}
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                })
                                            }
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="5">Seats</AccordionHeader>
                                        <AccordionBody accordionId="5">
                                            <h6 className="filter-title mb-2">Seats</h6>
                                            {
                                                filter && filter?.seats?.map((value, index) => {
                                                    return (
                                                        <FormGroup key={index} check className="ms-2 mt-2 mb-2 d-flex align-items-center">
                                                            <Input className="mt-0 me-2" checked={filtered?.seats?.includes(value)} onChange={checkOnchange} type="checkbox" id={value} name={'seats'} value={value} />
                                                            <Label for={value} check>
                                                                {value + " Seater"}
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                })
                                            }
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="6">Fastag</AccordionHeader>
                                        <AccordionBody accordionId="6">
                                            <FormGroup check className="ms-2 mt-2 mb-2 d-flex align-items-center">
                                                <Input className="mt-0 me-2" type="checkbox" id={'fastag'} name={'fastag'} value={filter?.fastag?.fastag} />
                                                <Label for={'fastag'} check>
                                                    {"With Fastag"}
                                                </Label>
                                            </FormGroup>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <h6 className="filter-title mb-2 ms-3">Packages</h6>
                                    <Row className="mx-3">
                                        {
                                            filter && filter?.priceType?.map((value, index) => {
                                                return (
                                                    <FormGroup key={index} check className="ms-2 mt-2 mb-2 d-flex align-items-center">
                                                        <Input className="mt-0 me-2" checked={filtered?.priceType == (value)} onChange={radioOnchange} type="radio" id={value + "KM"} name={'priceType'} value={value} />
                                                        <Label for={value + "KM"} check>
                                                            {value + " Km/Hour"}
                                                        </Label>
                                                    </FormGroup>
                                                )
                                            })
                                        }
                                    </Row>
                                </Accordion>
                            </div>
                        </Col>
                        <Col xs={10}>
                            <Row className="location-date justify-content-around">
                                <Col xs={12} lg={3}>
                                    <Label>Search Location :</Label>
                                    <PlaceInput setPlace={setLocation} />
                                </Col>
                                <Col xs={12} md={3}>
                                    <Label for="startDate">From</Label>
                                    <Col>
                                        {/* <DatePicker
                                            className="calender-theme"
                                            plugins={[<TimePicker position="right" hStep={1} mStep={0} sStep={0} />]}
                                            // inputClass="date-theme"
                                            format="DD-MM-YYYY HH:mm"
                                            value={filtered?.startDate}
                                            onChange={(e) => setFiltered((prev) => ({ ...prev, startDate: e }))}
                                            minDate={minStartDate()} // Set min time to the calculated minStartDate()
                                            maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
                                        /> */}
                                        <DatePicker
                                            className="calender-theme"
                                            plugins={[<TimePicker position="right" hStep={1} mStep={0} sStep={0} />]}
                                            minDate={new Date(new Date()?.getFullYear(), new Date().getMonth(), new Date()?.getDate(), new Date()?.getHours() + 2)}
                                            onChange={(e) => { setFiltered((prev) => ({ ...prev, startDate: new Date(e), endDate: new Date(new Date(e).setHours(new Date(e).getHours() + 8)) })) }}
                                            format="DD/MM/YYYY hh:00 A"
                                            value={filtered?.startDate}
                                        />

                                    </Col>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Label for="endDate">To</Label>
                                    <Col>
                                        <DatePicker
                                            className="calender-theme"
                                            plugins={[<TimePicker position="right" />]}
                                            minDate={new Date(new Date(filtered?.startDate).setHours(new Date(filtered?.startDate).getHours() + 8))}
                                            onChange={(e) => { setFiltered((prev) => ({ ...prev, endDate: new Date(e) })) }}
                                            value={filtered?.endDate}
                                            format="DD/MM/YYYY hh:00 A"
                                        />
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="justify-content-end mt-3">
                                <Col xs={12} lg={3}>
                                    <Select className="price-filter" value={filtered?.sort} isOptionSelected={priceFilter} options={priceFilter} onChange={(e) => { handleSelectChange(e, 'sort') }} placeholder='Price Filter'>
                                    </Select>
                                </Col>
                            </Row>
                            <section className="top-deals mt-5 p-5">
                                <Container fluid>
                                    <Row className="tariff-list-home justify-content-center">
                                        {filtered?.lat && filtered?.lng ?
                                            carList && carList?.length ? carList?.map((cars, index) => {
                                                return (
                                                    <Col key={index} xs={12} sm={10} md={6} xl={4}>
                                                        <Card className="px-3 py-4 mb-3">
                                                            <Row className="align-items-center">
                                                                <Col xs={6}>
                                                                    <h6 className="fw-semibold mb-3">{cars?.modelName}</h6>
                                                                    <div className="d-flex align-items-center rent">
                                                                        <h5 className="price">{Number(hours) * Number(cars?.priceTypes?.[cars?.priceTypes?.findIndex((val) => filtered?.priceType == val?.perHourKm)]?.perHourPrice)}</h5>
                                                                        <div className="ms-2 per-day"><span> / Hour</span></div>
                                                                    </div>
                                                                    <div className="d-flex flex-column align-items-center rent">
                                                                        <div>{cars?.transmissionType}</div>
                                                                        <div className="ms-2">{cars?.fuelType}</div>
                                                                    </div>
                                                                    <Button className="mt-3 btn-sm bk-now" onClick={() => navigate(`/booking/car/${cars?.carId}?startDate=${new Date(filtered?.startDate).getTime()}&endDate=${new Date(filtered?.endDate).getTime()}&package=${filtered?.priceType == "15" ? `Package 1` : filtered?.priceType == "20" ? `Package 2` : `Package 3`}`)}>Book Now</Button>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <Link to={`/booking/car/${cars?.carId}?startDate=${new Date(filtered?.startDate).getTime()}&endDate=${new Date(filtered?.endDate).getTime()}&package=${filtered?.priceType == "15" ? `Package 1` : filtered?.priceType == "20" ? `Package 2` : `Package 3`}`} ><img className="car-image" src={cars?.carImages[0]} alt="car-image" /></Link>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                )
                                            }) : "No Records Found" : 'Cars Not Found in this Location'}
                                    </Row>
                                </Container>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Registration;