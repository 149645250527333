import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { getApiCall, getCookie, patchApiCall, postApiCall, reloadWindow } from 'gfdu'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { decrypt } from '../../Helpers/Crypto'
import { siteDetailChange } from '../../Reducer/siteDetailsSlice'
import { contentcontentLoadingChange, contentLoadingChange } from '../../Reducer/componentSlice'


const Brand = () => {
  const formData = new FormData();
  const [details, setDetails] = useState({ siteName: "", siteTitle: "", logo: "", favIcon: "" })
  const [logoFile, setLogoFile] = useState(null);
  const [favIconFile, setFavIconFile] = useState(null);
  const [disableButton,setDisableButton] = useState(false)
  const handleChange = (e) => {
    setDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  const imageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (e.target.name === 'logo') {
        setLogoFile(file);
      } else {
        setFavIconFile(file);
      }
      setDetails((prev) => ({
        ...prev,
        [e.target.name]: URL.createObjectURL(file)
      }));
    }
  };
  const siteData = useSelector((state) => state.siteDetail)
  const dispatch = useDispatch()
  const apiCall = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (siteData?.siteDetail?.siteName !== details?.siteName) {
      formData.append("siteName", details?.siteName);
    }
    if (siteData?.siteDetail?.siteTitle !== details?.siteTitle) {
      formData.append("siteTitle", details?.siteTitle);
    }
    if (logoFile) {
      formData.append("logo", logoFile);
    }
    if (favIconFile) {
      formData.append("favIcon", favIconFile);
    }
    patchApiCall("/admin/business/site-details", formData, (response) => {
      if (response?.success) {
        toast.success("Updated Successfully");
        setTimeout(() => {
          reloadWindow()
          setDisableButton(false)
        }, 3000);
      } else {
        toast.error("Update Failed");
        setDisableButton(false)
      }
    })
  };
  const onSubmit = () => {
    if (!(siteData?.siteDetail?.siteName === details?.siteName) && details?.siteName === "") {
      toast.error("Invalid Site Name")
    } else if (!(siteData?.siteDetail?.siteTitle === details?.siteTitle) && details?.siteTitle === "") {
      toast.error("Invalid Site Title")
    } else if (logoFile && details?.logo === "") {
      toast.error("Invalid Site logo");
    } else if (favIconFile && details?.favIcon === "") {
      toast.error("Invalid Site favIcon");
    } else {
      apiCall()
    }
  }
  useEffect(() => {
    dispatch(contentLoadingChange(true))
    getApiCall("/admin-auth/site-details", (response) => {
      if (response.success) {
        const decryptData = decrypt(response?.data)
        dispatch(siteDetailChange(decryptData?.data))
        setDetails(decryptData?.data)
      } else {
        toast.error("Failed to retrive details")
      }
      dispatch(contentLoadingChange(false))
    })
  }, [])
  return (
    <React.Fragment>
      <Container fluid>
        <Row className='flex-column'>
          <Col className='text-center fs-3 my-2' >Site Details</Col>
          <Col className='table-card' >
            <Card className='m-4 tab-card '>
              <CardBody>
                <Row lg={5} className='justify-content-evenly'>
                  <Col lg={5}>
                    <FormGroup row>
                      <Label sm={3}>Site Name</Label>
                      <Col><Input type='text' id='siteName' name='siteName' value={details?.siteName || ""} onChange={handleChange} className='' /></Col>
                    </FormGroup>
                  </Col>
                  <Col lg={5}>
                    <FormGroup row>
                      <Label sm={3}>Title</Label>
                      <Col><Input type='text' id='siteTitle' name='siteTitle' value={details?.siteTitle || ""} onChange={handleChange} className='' /></Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row lg={5} className='justify-content-evenly my-3'>
                  <Col lg={5} className='d-flex justify-content-center' >
                    <FormGroup className='text-center'>
                      <Col className='site-image-upload'>
                        <Label htmlFor='logo'> {details?.logo && <img src={details?.logo} alt='logo' />}</Label>
                        <input type='file' id='logo' name='logo' onChange={imageChange} />
                      </Col>
                      <Label htmlFor='logo'><i className="fa-solid fa-upload"></i> Upload Logo</Label>
                    </FormGroup>
                  </Col>
                  <Col lg={5} className='d-flex justify-content-center' >
                    <FormGroup className='text-center'>
                      <Col className='site-image-upload'>
                        <Label htmlFor='favIcon'>{details?.favIcon && <img src={details?.favIcon} alt='favIcon' />}</Label>
                        <input type='file' id='favIcon' name='favIcon' onChange={imageChange} />
                      </Col>
                      <Label htmlFor='favIcon'><i className="fa-solid fa-upload"></i> Upload FavIcon</Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-center' ><Button className='button-theme' disabled={disableButton} onClick={onSubmit} >Update</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Brand