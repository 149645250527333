import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { randomId } from '../../../Helpers/Utils'
import { patchApiCall, reloadWindow } from "gfdu";
import { useParams } from "react-router-dom";
import Select from "react-select";

const Complete = () => {
    const [disable, setDisable] = useState(false)
    const [detail, setDetail] = useState({})
    const [imageTemp, setImageTemp] = useState([])
    const { bookingId } = useParams()
    const [endDamages, setEndDamages] = useState([])
    const damageLevel = [{ label: "Minor", value: "Minor" }, { label: "Major", value: "Major" }, { label: "Damaged", value: "Damaged" }]

    const handleChange = (e) => {
        if (e.target.value == Number(e.target.value)) { setDetail((prev) => ({ ...prev, [e.target.name]: e.target.value })) }
    }
    const imageChange = (e) => {
        const imageDatas = Object?.values(e.target.files)
        if (imageDatas && imageDatas?.length !== 0) {
            imageDatas?.map((value) => {
                if (value?.size <= 10000000) {
                    setImageTemp((prev) => {
                        return [
                            ...prev,
                            {
                                imgId: randomId(),
                                file: value,
                                url: URL.createObjectURL(value)
                            }
                        ]
                    })
                } else {
                    toast?.error(`Image Must to be below 10 MB`)
                }
            })
        } else {
            toast?.error("kindly select correct images")
        }
    };
    const handleDamage = (key, value, index) => {
        setEndDamages(endDamages?.map((element, i) => {
            if (i === index) {
                element[key] = value
            }
            return element
        }))
    }
    const cancelImage = (imgId) => {
        setImageTemp(
            imageTemp?.filter((value) => {
                return value?.imgId !== imgId
            })
        )
    }
    const handleSubmit = () => {
        if (!detail?.endFuel) {
            toast.error("End Fuel is required")
        } else if (Number(detail?.endFuel) < 0 || Number(detail?.endFuel) > 100) {
            toast.error("Invalid End fuel")
        } else if (!detail?.endKms) {
            toast.error("End KMS is required")
        } else if (!Number(detail?.endKms)) {
            toast.error("Invalid End KMS")
        } else if (!detail?.endFastTag) {
            toast.error("End Fasttag is required")
        } else if (imageTemp?.length !== 5) {
            toast.error("5 images is required")
        } else {
            setDisable(true)
            const formData = new FormData();
            formData.append('endFuel', Number(detail?.endFuel))
            formData.append('endFastTag', Number(detail?.endFastTag))
            formData.append('endKms', Number(detail?.endKms))
            imageTemp?.map(element => formData.append('endImage', element?.file))
            const damage = endDamages?.filter((element) => {
                if (element?.partName !== "" && element?.damageLevel !== "") return { partName: element?.partName?.trim(), damageLevel: element?.damageLevel?.trim() }
            })
            damage?.length && formData?.append('endDamages', JSON.stringify(damage))
            patchApiCall(`/admin/booking/trip/close/${bookingId}`, formData, (response) => {
                if (response?.success) {
                    toast.success("Delivered Successfully")
                    setTimeout(() => {
                        reloadWindow();
                    }, 1500);
                } else {
                    setDisable(false)
                    toast.error(response?.message)
                }
            })
        }
    }

    return (
        <React.Fragment>
            <Row className="p-3 px-5">
                <Col md={5}>
                    <FormGroup row>
                        <Label for="endFuel" sm={3}>End Fuel</Label>
                        <Col sm={5}>
                            <Input id="endFuel" disabled={disable} name="endFuel" className='input-theme text-upper input-size-2' onChange={handleChange} type="text" value={detail?.endFuel || ""} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="endKms" sm={3}>End KMS</Label>
                        <Col sm={5}>
                            <Input id="endKms" disabled={disable} name="endKms" className='input-theme text-upper input-size-2' type="text" onChange={handleChange} value={detail?.endKms || ""} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="endFastTag" sm={3}>End Fastag</Label>
                        <Col sm={5}>
                            <Input id="endFastTag" disabled={disable} name="endFastTag" className='input-theme text-upper input-size-2' onChange={handleChange} type="text" value={detail?.endFastTag || ""} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md={7}>
                    <FormGroup row>
                        <Label sm={3}>End Damages</Label>
                        <Col sm={9}>
                            {
                                endDamages?.length > 0 ?
                                    endDamages?.map((element, i) => {
                                        return (
                                            <Row className="align-items-center mb-3" key={i}>
                                                <Col sm={6}>
                                                    <Input name="partName" disabled={disable} className='input-theme text-upper' onChange={(e) => { handleDamage('partName', e.target.value, i) }} type="text" value={element?.partName || ""} />
                                                </Col>
                                                <Col sm={6}>
                                                    <Select isDisabled={disable} styles={{
                                                        control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                        option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                    }} name="damageLevel" value={{ label: element?.damageLevel, value: element?.damageLevel }} onChange={(e) => { handleDamage('damageLevel', e.value, i) }} className="ms-2" options={damageLevel} />
                                                </Col>
                                            </Row>
                                        )

                                    }) : ""
                            }
                            {
                                ((endDamages?.length && endDamages[endDamages?.length - 1]?.partName !== "" && endDamages[endDamages?.length - 1]?.damageLevel !== "") || endDamages?.length === 0) ?
                                    <Col sm={1} className="d-flex align-items-center text-center"><i className='ms-2 bx bx-plus-circle bx-md cursor-pointer' onClick={() => { setEndDamages((prev) => ([...prev, { partName: '', damageLevel: '' }])) }}></i></Col>
                                    : ""}
                        </Col>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <div className='image-add-card1'>
                        {
                            imageTemp && imageTemp?.length !== 0 &&
                            imageTemp?.map((value, i) => {
                                if (i < 5) {
                                    return (
                                        <div className='image-view-box' key={value?.imgId}>
                                            <span className='cancel-image' onClick={() => cancelImage(value?.imgId)} ><i className='bx bx-x'></i></span>
                                            <span ><img src={value?.url} alt={value?.imgId} /></span>
                                        </div>
                                    )
                                }
                            })
                        }

                        {
                            imageTemp && imageTemp?.length < 5 &&
                            <div className='image-box' ><input disabled={disable} onChange={imageChange} type='file' multiple="multiple" accept="image/png,image/jpeg" /> <i className="bi bi-plus-lg"></i></div>
                        }
                    </div>
                </Col>
                <Col className="text-center mt-4">
                    <Button className="button-theme" disabled={disable} onClick={() => handleSubmit()}>Deliver Now</Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Complete;