import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { randomId } from '../../../Helpers/Utils'
import { useParams } from "react-router-dom";
import { patchApiCall, reloadWindow } from "gfdu";

const Delivery = () => {
    const [detail, setDetail] = useState({})
    const [imageTemp, setImageTemp] = useState([])
    const { bookingId } = useParams()
    const [disable, setDisable] = useState(false)
    const imageChange = (e) => {
        const imageDatas = Object?.values(e.target.files)
        if (imageDatas && imageDatas?.length !== 0) {
            imageDatas?.map((value) => {
                if (value?.size <= 10000000) {
                    setImageTemp((prev) => {
                        return [
                            ...prev,
                            {
                                imgId: randomId(),
                                file: value,
                                url: URL.createObjectURL(value)
                            }
                        ]
                    })
                } else {
                    toast?.error(`Image Must to be below 10 MB`)
                }
            })
        } else {
            toast?.error("kindly select correct images")
        }
    };
    const cancelImage = (imgId) => {
        setImageTemp(
            imageTemp?.filter((value) => {
                return value?.imgId !== imgId
            })
        )
    }
    const handleChange = (e) => {
        if (e.target.value == Number(e.target.value)) { setDetail((prev) => ({ ...prev, [e.target.name]: e.target.value })) }
    }
    const handleSubmit = () => {
        if (!detail?.startFuel) {
            toast.error("Start Fuel is required")
        } else if (Number(detail?.startFuel) < 0 || Number(detail?.startFuel) > 100) {
            toast.error("Invalid start fuel")
        } else if (!detail?.startKms) {
            toast.error("Start KMS is required")
        } else if (!Number(detail?.startKms)) {
            toast.error("Invalid Start KMS")
        } else if (!detail?.startFastTag) {
            toast.error("Start Fasttag is required")
        } else if (imageTemp?.length !== 5) {
            toast.error("5 images is required")
        } else {
            setDisable(true)
            const formData = new FormData();
            formData.append('startFuel', Number(detail?.startFuel))
            formData.append('startFastTag', Number(detail?.startFastTag))
            formData.append('startKms', Number(detail?.startKms))
            imageTemp?.map(element => formData.append('startImage', element?.file))
            patchApiCall(`/admin/booking/trip/start/${bookingId}`, formData, (response) => {
                if (response?.success) {
                    toast.success("Delivered Successfully")
                    setTimeout(() => {
                        reloadWindow();
                    }, 1500);
                } else {
                    setDisable(false)
                    toast.error(response?.message)
                }
            })
        }
    }
    useEffect(() => {
        if (imageTemp?.length > 5) {
            setImageTemp(imageTemp.slice(0, 5))
        }
    }, [imageTemp])
    return (
        <React.Fragment>
            <Row className="py-3">
                <Col md={5}>
                    <FormGroup row className='justify-content-center'>
                        <Label for="startFuel" sm={3}>Start Fuel</Label>
                        <Col sm={5}>
                            <Input id="startFuel" disabled={disable} name="startFuel" className='input-theme text-upper input-size-2' onChange={handleChange} type="text" value={detail?.startFuel || ""} />
                        </Col>
                    </FormGroup>
                    <FormGroup row className='justify-content-center'>
                        <Label for="startKms" sm={3}>Start KMS</Label>
                        <Col sm={5}>
                            <Input id="startKms" disabled={disable} name="startKms" className='input-theme text-upper input-size-2' type="text" onChange={handleChange} value={detail?.startKms || ""} />
                        </Col>
                    </FormGroup>
                    <FormGroup row className='justify-content-center'>
                        <Label for="startFastTag" sm={3}>Start Fastag</Label>
                        <Col sm={5}>
                            <Input id="startFastTag" disabled={disable} name="startFastTag" className='input-theme text-upper input-size-2' onChange={handleChange} type="text" value={detail?.startFastTag || ""} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md={7}>
                    <div className='image-add-card1'>
                        {
                            imageTemp && imageTemp?.length !== 0 &&
                            imageTemp?.map((value, i) => {
                                if (i < 5) {
                                    return (
                                        <div className='image-view-box' key={value?.imgId}>
                                            <span className='cancel-image' onClick={() => cancelImage(value?.imgId)} ><i className='bx bx-x'></i></span>
                                            <span ><img src={value?.url} alt={value?.imgId} /></span>
                                        </div>
                                    )
                                }
                            })
                        }

                        {
                            imageTemp && imageTemp?.length < 5 &&
                            <div className='image-box' ><input disabled={disable} onChange={imageChange} type='file' multiple="multiple" accept="image/png,image/jpeg" /> <i className="bi bi-plus-lg"></i></div>
                        }
                    </div>
                </Col>
                <Col className="text-center mt-4">
                    <Button className="button-theme" disabled={disable} onClick={() => handleSubmit()}>Deliver Now</Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Delivery;