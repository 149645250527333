import { getApiCall, reloadWindow, patchApiCall } from 'gfdu'
import React, { useEffect, useState } from 'react'
import { decrypt, encrypt } from '../../../Helpers/Crypto'
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import moment from 'moment'
import Config from '../../../Helpers/Config';
import { useDispatch } from "react-redux";
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import TablePlaceHolder from "../../../Helpers/Components/TablePlaceHolder";
import Pagination from "../../../Helpers/Components/Pagination";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import Delivery from './Delivery';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { useParams } from 'react-router-dom';
import Complete from './Complete'
import Close from './Close';

const BookingView = () => {
    const [car, setCar] = useState({});
    const [siteDetails, setSiteDetails] = useState();
    const [currentLocation, setCurrentLocation] = useState({ lat: 12.957639, lng: 80.142861 });
    const { bookingId } = useParams();
    const dispatch = useDispatch();
    const [logModal, setLogModal] = useState(false)
    const [next1, setNext1] = useState(false)
    const [log, setLog] = useState([])
    const [pageNo1, setPageNo1] = useState(0)
    const [detail, setDetail] = useState({})
    const [view, setView] = useState(0)
    const [deliveryModal, setDeliveryModal] = useState(false);
    const [dateExte, setDateExte] = useState({})
    const [disable, setDisable] = useState(false)
    const [completeModal, setCompleteModal] = useState(false)
    const [closeModal, setCloseModal] = useState(false)

    const dateFormat = (date) => {
        const createdAt = new Date(date)
        return createdAt?.getDate() + "-" + (createdAt?.getMonth() + 1) + "-" + createdAt?.getFullYear() + " " + createdAt?.getHours() + ":" + createdAt?.getMinutes()
    }
    const paymentComplete = (price, orderId, value) => {
        const options = {
            key: Config?.KEYS?.SECRET_KEY,
            amount: price,
            currency: 'INR',
            name: siteDetails?.title,
            description: 'Test Transaction',
            order_id: orderId,
            handler: (response) => {
                dispatch(contentLoadingChange(true))
                setDisable(true)
                patchApiCall(`/admin/booking/${value}/${bookingId}`, {}, (response) => {
                    if (response?.success) {
                        toast.success("Payment Completed")
                        setTimeout(() => {
                            reloadWindow();
                        }, 1500);
                    } else {
                        setDisable(false)
                        dispatch(contentLoadingChange(false))
                        toast.error("Unable to pay")
                    }
                })
            },
            prefill: {
                name: car?.name,
                email: car?.email?.emaiId,
                contact: car?.phone,
            },
            theme: { color: '#ff4c30' },
            image: siteDetails?.favIcon,
            modal: { ondismiss: () => { } },
        };
        const razorpay = new window.Razorpay(options);
        razorpay.open();
    }
    const renderTableRows = (data, parentKey = "") => {
        if (typeof data === "object" && data !== null) {
            return Object.entries(data)
                .filter(([_, value]) => value !== null && JSON.stringify(value) !== "{}")
                .map(([key, value], index) => {
                    const currentKey = parentKey ? `${parentKey} - ${key}` : key;
                    if (typeof value === "object" && value !== null) {
                        return (
                            <React.Fragment key={index}>
                                {renderTableRows(value, currentKey)}
                            </React.Fragment>
                        );
                    }
                    return (
                        <tr key={index}>
                            <td>{currentKey}</td>
                            <td>{value?.toString() || "N/A"}</td>
                        </tr>
                    );
                });
        }
        return null;
    };
    const handleExtension = (date) => {
        dispatch(contentLoadingChange(true))
        setDisable(true)
        patchApiCall(`/admin/booking/trip/extend/${bookingId}`, encrypt({ endDate: date }), (response) => {
            dispatch(contentLoadingChange(false))
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                const options = {
                    key: Config?.KEYS?.SECRET_KEY,
                    amount: decryptedData?.data?.extendAmount,
                    currency: 'INR',
                    name: siteDetails?.title,
                    description: 'Test Transaction',
                    order_id: decryptedData?.data?.orderId,
                    handler: (response) => {
                        dispatch(contentLoadingChange(true))
                        setDisable(true)
                        patchApiCall(`/admin/booking/trip/extend/payment/${bookingId}`, {}, (response) => {
                            if (response?.success) {
                                toast.success("Payment Completed")
                                setTimeout(() => {
                                    reloadWindow();
                                }, 1500);
                            } else {
                                setDisable(false)
                                dispatch(contentLoadingChange(false))
                                toast.error(response?.message)
                            }
                        })
                    },
                    prefill: {
                        name: car?.name,
                        email: car?.email?.emaiId,
                        contact: car?.phone,
                    },
                    theme: { color: '#ff4c30' },
                    image: siteDetails?.favIcon,
                    modal: { ondismiss: () => { setDisable(false); reloadWindow(); } },
                };
                const razorpay = new window.Razorpay(options);
                razorpay.open();
            } else {
                setDisable(false)
                toast.error(response?.message)
            }
        })
    }
    const handleFineCharges = () => {
        
    }
    useEffect(() => {
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/booking/changes/log/${bookingId}?pageNo=${pageNo1}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setNext1(decryptedData?.data?.next)
                    setLog(decryptedData?.data?.currentPage)
                    setDetail(decryptedData?.data?.currentPage?.length ? decryptedData?.data?.currentPage[0] : {})
                } else {
                    setNext1(false)
                    setLog([])
                    setDetail({})
                    toast.error(response?.message)
                }
            } else {
                setNext1(false)
                setLog([])
                setDetail({})
            }
            dispatch(contentLoadingChange(false))
        })

    }, [pageNo1, logModal])
    useEffect(() => {
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/booking/detail/${bookingId}`, (response) => {
            dispatch(contentLoadingChange(false))
            if (response?.success) {
                const decryptedData = decrypt(response?.data)?.data
                setCar(decryptedData)
                const perhourPrice = (decryptedData?.preBookDetails?.package && decryptedData?.priceTypes?.length) ?
                    decryptedData?.priceTypes?.filter((element) => element?.package === decryptedData?.preBookDetails?.package) : ""
                const differenceInMilliseconds = new Date(decryptedData?.preBookDetails?.endDate) - new Date(decryptedData?.preBookDetails?.startDate);
                const hours = differenceInMilliseconds / (1000 * 60 * 60);
                const dateExtend = new Date(decryptedData?.preBookDetails?.endDate)
                dateExtend.setHours(dateExtend?.getHours() + 1)
                const ext = 48 - (decryptedData?.tripDetails?.extendedDetails?.reduce((total, value) => value?.paymentDetails?.status !== "failed" && total + value?.extendHour, 0) || 0)
                const maxHour = new Date(decryptedData?.preBookDetails?.endDate)
                maxHour.setHours(maxHour?.getHours() + ext)
                setDateExte({ min: dateExtend, max: maxHour, current: dateExtend })
                setCar((prev) => ({ ...prev, carImage: decryptedData?.carImages[0], perHourKm: perhourPrice[0]?.perHourKm, perHourPrice: perhourPrice[0]?.perHourPrice, totalHours: Math.ceil(hours) }))
            } else {
                toast.error(response?.message)
            }
        })
        getApiCall(`/admin-auth/site-details`, (response) => {
            if (response?.success) {
                setSiteDetails(decrypt(response?.data)?.data)
            } else {
                toast.error(response?.message)
            }
        })

    }, [])

    return (
        <React.Fragment>
            <Modal size='xl' isOpen={logModal} toggle={() => setLogModal(false)}>
                <ModalHeader>{bookingId}</ModalHeader>
                <ModalBody className=' px-4 text-center'>
                    <Row>
                        <Col md={7}>
                            <Table className="table-bg" borderless >
                                <thead>
                                    <tr className="border-bottom" >
                                        <th>Action</th>
                                        <th>Updated By</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        log ? (log && log?.length !== 0 ? log?.map((value, key) => {
                                            return (
                                                <tr key={key} className={`cursor-pointer-hover ${view === key ? "chosenLog" : ""}`} onClick={() => { setView(key); setDetail(value); setLogModal(true) }} >
                                                    <td>{value?.action}</td>
                                                    <td>{value?.userId}</td>
                                                    <td>{dateFormat(value?.createdAt)}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                                            : <TablePlaceHolder count={4} />
                                    }
                                </tbody>
                            </Table>
                            <Pagination pageNo={pageNo1} changePage={setPageNo1} next={next1} />
                        </Col>
                        <Col md={5}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail?.previousData ? (
                                        renderTableRows(detail.previousData)
                                    ) : (
                                        <tr>
                                            <td colSpan="2" className="text-center">
                                                No previous data available.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="xl" isOpen={deliveryModal} >
                <ModalHeader toggle={() => setDeliveryModal(false)}>Delivery Details</ModalHeader>
                <ModalBody>
                    <Delivery />
                </ModalBody>
            </Modal>
            <Modal size="xl" isOpen={completeModal} >
                <ModalHeader toggle={() => setCompleteModal(false)}>Complete Booking</ModalHeader>
                <ModalBody>
                    <Complete />
                </ModalBody>
            </Modal>
            <Modal size="xl" isOpen={closeModal} >
                <ModalHeader toggle={() => setCloseModal(false)}>Close Booking</ModalHeader>
                <ModalBody>
                    <Close />
                </ModalBody>
            </Modal>
            <div className='branch'>
                <Container fluid>
                    <Row className="justify-content-between" >
                        <Col className='text-center'><h3>Booking Details</h3></Col>
                    </Row>
                </Container>
                <Card className='branch-card mb-3'>
                    <CardBody>
                        <Row>
                            <Col className='bookingViewCar' md={5}>
                                <img className='booked-car' src={car?.carImage || ""} alt='booked-car' />
                            </Col>
                            <Col md={7}>
                                <div className={`${car?.bookingStage === 'Completed' ? "success" : 'info'} d-flex align-items-center justify-content-center`}>{`${car?.bookingStage}`} <i className='bx ms-2 bx-sm cursor-pointer bx-info-circle' onClick={() => { setLogModal(true) }}></i> </div>
                                <Row className='mt-4'>
                                    <Col>
                                        <FormGroup className='align-items-center formInput' row>
                                            <Label className='fw-bolder' lg={4}> Cust ID </Label>
                                            <Col lg={6}>
                                                <Label className='mb-0' >{car?.customerId}</Label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className='align-items-center formInput' row>
                                            <Label className='fw-bolder' lg={4}> Name  </Label>
                                            <Col lg={6}>
                                                <Label className='mb-0' >{car?.name}</Label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className='align-items-center formInput' row>
                                            <Label className='fw-bolder' lg={4}> Phone </Label>
                                            <Col lg={6}>
                                                <Label className='mb-0' >{car?.phone}</Label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className='align-items-center formInput' row>
                                            <Label className='fw-bolder' lg={4}> Email </Label>
                                            <Col lg={6}>
                                                <Label className='mb-0' >{car?.email?.emailId}</Label>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className='align-items-center formInput' row>
                                            <Label className='fw-bolder' lg={4}> Brand </Label>
                                            <Col lg={6}>
                                                <Label className='mb-0' >{car?.brand}</Label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className='align-items-center formInput' row>
                                            <Label className='fw-bolder' lg={4}> Model  </Label>
                                            <Col lg={6}>
                                                <Label className='mb-0' >{car?.modelName}</Label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className='align-items-center formInput' row>
                                            <Label className='fw-bolder' lg={4}> Year </Label>
                                            <Col lg={6}>
                                                <Label className='mb-0' >{car?.year}</Label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className='align-items-center formInput' row>
                                            <Label className='fw-bolder' lg={4}> Fuel  </Label>
                                            <Col lg={6}>
                                                <Label className='mb-0' >{car?.fuelType}</Label>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col md={6} xs={12}>
                                        <div className="mt-3 mb-1">
                                            <i className="bi bi-geo-alt-fill gmd-color me-2"></i>
                                            {car?.location?.street + " , " + car?.location?.locality}
                                        </div>
                                        <div className="mb-2 ms-4">
                                            {car?.location?.city + " , " + car?.location?.state}
                                        </div>
                                        <div className="mb-3 ms-4">
                                            {car?.location?.country + " , " + car?.location?.pincode}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} className='text-center mt-3'>
                                        <div className='mb-3 start'> <span>{"Start " + " - " + moment(new Date(car?.preBookDetails?.startDate)).format('llll')}</span> </div>
                                        <div className='end'> <span>{"End " + " - " + moment(new Date(car?.preBookDetails?.endDate)).format('llll')}</span> </div>
                                    </Col>
                                </Row>
                                <div className='text-center'>
                                    {
                                        car?.bookingStage === 'Initiated' && <Button disabled={disable} className='button-theme' onClick={() => paymentComplete(car?.preBookDetails?.totalPrice, car?.preBookDetails?.paymentDetails?.orderId, "payment")}>Pay Now</Button>
                                    }
                                    {
                                        car?.bookingStage === 'Booked' && <Button disabled={disable} className='button-theme' onClick={() => setDeliveryModal(true)}>Deliver</Button>
                                    }
                                    {
                                        (car?.bookingStage === 'Started' && (!car?.tripDetails?.extendedDetails || (car?.tripDetails?.extendedDetails?.length < 3 && !car?.tripDetails?.extendedDetails.some(value => value?.paymentDetails?.status === "created") && car?.tripDetails?.extendedDetails?.reduce((total, value) => value?.paymentDetails?.status !== "failed" && total + value?.extendHour, 0) < 48))) ?
                                            <DatePicker
                                                className="rmdp-mobile date-style"
                                                plugins={[<TimePicker position="bottom" />]}
                                                render={<Button disabled={disable} className='button-theme'>Extension</Button>}
                                                mobileLabels={{
                                                    OK: "Accept",
                                                    CANCEL: "Close",
                                                }}
                                                minDate={dateExte?.min}
                                                maxDate={dateExte?.max}
                                                value={dateExte?.current}
                                                onChange={(e) => { handleExtension(new Date(e)) }}
                                                format="DD/MM/YYYY hh:mm A"
                                            />
                                            : ""
                                    }
                                    {
                                        car?.tripDetails?.extendedDetails ? (car?.tripDetails?.extendedDetails[car?.tripDetails?.extendedDetails?.length - 1]?.paymentDetails?.status === "created" &&
                                            <Button className='button-theme' onClick={() => { paymentComplete(car?.tripDetails?.extendedDetails[car?.tripDetails?.extendedDetails?.length - 1]?.extendAmount, car?.tripDetails?.extendedDetails[car?.tripDetails?.extendedDetails?.length - 1]?.paymentDetails?.orderId, "trip/extend/payment") }} > Extension Pay </Button>) : ""
                                    }
                                    {
                                        car?.bookingStage === "Started" && <Button className='ms-2 button-theme' onClick={() => { setCompleteModal(true) }}>Complete</Button>
                                    }
                                    {
                                        car?.bookingStage === "Completed" && <Button className='button-theme' onClick={() => { setCloseModal(true) }}>Close</Button>
                                    }
                                    {
                                        car?.bookingStage === "Pending" && <Button className='button-theme' onClick={handleFineCharges}>Pay Now</Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className='branch-card'>
                    <CardBody>
                        <Row>
                            <Col className='d-flex flex-column align-items-center'>
                                <Label className='fw-bold'>Package</Label>
                                <Label>{car?.perHourKm || ""} KM/HR </Label>
                            </Col>
                            <Col className='d-flex flex-column align-items-center'>
                                <Label className='fw-bold'>Per Hour Price</Label>
                                <Label>{car?.perHourPrice || ""}</Label>
                            </Col>
                            <Col className='d-flex flex-column align-items-center'>
                                <Label className='fw-bold'>Total Hours</Label>
                                <Label>{car?.totalHours || ""} Hrs</Label>
                            </Col>
                            <Col className='d-flex flex-column align-items-center'>
                                <Label className='fw-bold'>Actual price</Label>
                                <Label>{car?.preBookDetails?.actualPrice || ""}</Label>
                            </Col>
                            <Col className='d-flex flex-column align-items-center'>
                                <Label className='fw-bold'>DOP price</Label>
                                <Label>{(Number(car?.preBookDetails?.DOPDetails?.deliveryDetails?.price) + Number(car?.preBookDetails?.DOPDetails?.pickupDetails?.price)) || ""}</Label>
                            </Col>
                            <Col className='d-flex flex-column align-items-center'>
                                <Label className='fw-bold'>Total Price</Label>
                                <Label>{car?.preBookDetails?.totalPrice || ""}</Label>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className='branch-card mt-3'>
                    <CardBody>
                        <APIProvider apiKey={Config?.KEYS?.MAP_KEY}>
                            <Map style={{ height: '400px' }} mapId={"bf51a910020fa25a"} defaultZoom={10} defaultCenter={currentLocation} gestureHandling={"cooperative"} mapTypeControl={true} zoomControl={true} disableDefaultUI={true}>
                                <AdvancedMarker position={car?.preBookDetails?.DOPDetails?.deliveryDetails?.latitude && car?.preBookDetails?.DOPDetails?.deliveryDetails?.longitude ? { lat: Number(car?.preBookDetails?.DOPDetails?.deliveryDetails?.latitude), lng: Number(car?.preBookDetails?.DOPDetails?.deliveryDetails?.longitude) } : undefined} />
                                <AdvancedMarker position={car?.preBookDetails?.DOPDetails?.pickupDetails?.latitude && car?.preBookDetails?.DOPDetails?.pickupDetails?.longitude ? { lat: Number(car?.preBookDetails?.DOPDetails?.pickupDetails?.latitude), lng: Number(car?.preBookDetails?.DOPDetails?.pickupDetails?.longitude) } : undefined} />
                            </Map>
                        </APIProvider>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default BookingView