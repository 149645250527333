import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApiCall, patchApiCall, reloadWindow, reloadWindowToPath } from "gfdu"
import { useDispatch } from "react-redux";
import { contentLoadingChange } from '../../../Reducer/componentSlice'
import { toast } from "react-toastify";
import { decrypt, encrypt } from "../../../Helpers/Crypto"
import { Card, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Table, Button } from "reactstrap";
import Select from "react-select";
import TablePlaceHolder from "../../../Helpers/Components/TablePlaceHolder"
import Pagination from "../../../Helpers/Components/Pagination";

const View = () => {
    const { roleId } = useParams();
    const dispatch = useDispatch();
    const [roleName, setRoleName] = useState("")
    const [role, setRole] = useState([{ controller: "", action: [] }])
    const [updatedRoleName, setupdatedRoleName] = useState("")
    const [updatedRole, setUpdatedRole] = useState([{ controller: "", action: [] }])

    const [editModal, setEditModal] = useState(false)
    const [buttonDisable, setButtonDisable] = useState(false)
    const [logModal, setLogModal] = useState(false)
    const [next, setNext] = useState(false)
    const [log, setLog] = useState([])
    const [pageNo, setPageNo] = useState(0)
    const [detail, setDetail] = useState({})
    const [view, setView] = useState(0)

    const dateFormat = (date) => {
        const createdAt = new Date(date)
        return createdAt?.getDate() + "-" + (createdAt?.getMonth() + 1) + "-" + createdAt?.getFullYear() + " " + createdAt?.getHours() + ":" + createdAt?.getMinutes()
    }
    const controller = [
        { label: "Car", value: "car" },
        { label: "Customer", value: "customer" },
        { label: "Bike", value: "Bike" },
        { label: "Booking", value: "booking" }

    ]
    const action = [
        { label: "Read", value: "read" },
        { label: "Write", value: "write" },
        { label: "Update", value: "update" }
    ]
    const handleChange = (e, i) => {
        const check = updatedRole?.some((element, index) => {
            if (element?.controller === e.value && index !== i) {
                return true
            }
        })
        if (check) {
            toast.error("Controller is already chosen")
        } else {
            const updatedRoles = updatedRole?.map((element, index) => {
                if (i === index) {
                    return { controller: e.value, action: element?.action }
                } else {
                    return element
                }
            })
            setUpdatedRole(updatedRoles)
        }
    }
    const handleActionChange = (e, i) => {
        const updatedRoles = updatedRole?.map((element, index) => {
            if (i === index) {
                return { controller: element?.controller, action: Array.isArray(e) ? e.map(x => x.value) : [] }
            } else {
                return element
            }
        })
        setUpdatedRole(updatedRoles)
    }
    const onSubmit = () => {
        if (!/^[a-zA-Z\s]{2,}$/.test(updatedRoleName) || !updatedRoleName?.trim()) {
            toast.error("Invalid Name");
        } else {
            const check = updatedRole?.some((element, index) => {
                if (!element?.controller || !element?.action?.length) {
                    return true
                }
            })
            if (check) {
                toast.error('Choose proper values')
            } else {
                dispatch(contentLoadingChange(true))
                setButtonDisable(true)
                patchApiCall(`/admin/staff/update/role/${roleId}`, encrypt({ roleName: updatedRoleName, permission: updatedRole }), (response) => {
                    dispatch(contentLoadingChange(false))
                    if (response?.success) {
                        setTimeout(() => {
                            reloadWindow()
                        }, 1500);
                    } else {
                        setButtonDisable(false)
                        toast.error(response?.message)
                    }
                })
            }
        }
    }
    const handleNextSelect = () => {
        if (!updatedRole[updatedRole?.length - 1]?.controller) {
            toast.error("Choose role")
        } else if (!updatedRole[updatedRole?.length - 1]?.action?.length) {
            toast.error("Choose atleast one action")
        } else {
            setUpdatedRole((prev) => ([...prev, { controller: "", action: [] }]))
        }

    }
    const renderTableRows = (data, parentKey = "") => {
        if (typeof data === "object" && data !== null) {
            return Object.entries(data)
                .filter(([_, value]) => value !== null && JSON.stringify(value) !== "{}")
                .map(([key, value], index) => {
                    const currentKey = parentKey ? `${parentKey} - ${key}` : key;
                    if (typeof value === "object" && value !== null) {
                        return (
                            <React.Fragment key={index}>
                                {renderTableRows(value, currentKey)}
                            </React.Fragment>
                        );
                    }
                    return (
                        <tr key={index}>
                            <td>{currentKey}</td>
                            <td>{value?.toString() || "N/A"}</td>
                        </tr>
                    );
                });
        }
        return null;
    };
    useEffect(() => {
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/staff/role/detail/${roleId}`, (response) => {
            dispatch(contentLoadingChange(false))
            if (response?.success) {
                setRoleName(decrypt(response?.data)?.data?.roleName)
                setRole(decrypt(response?.data)?.data?.permission)
            } else {
                toast.error(response?.message)
            }
        })
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/staff/role/changes/log/${roleId}?pageNo=${pageNo}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setNext(decryptedData?.data?.next)
                    setLog(decryptedData?.data?.currentPage)
                    setDetail(decryptedData?.data?.currentPage?.length ? decryptedData?.data?.currentPage[0] : {})
                } else {
                    setNext(false)
                    toast.error(response?.message)
                }
            }
            dispatch(contentLoadingChange(false))
        })
    }, [pageNo])

    return (
        <React.Fragment>
            <Modal size='xl' isOpen={logModal} toggle={() => setLogModal(false)}>
                <ModalHeader>{roleId}</ModalHeader>
                <ModalBody className=' px-4 text-center'>
                    <Row>
                        <Col md={7}>
                            <Table className="table-bg" borderless >
                                <thead>
                                    <tr className="border-bottom" >
                                        <th>Action</th>
                                        <th>Updated By</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        log ? (log && log?.length !== 0 ? log?.map((value, key) => {
                                            return (
                                                <tr key={key} className={`cursor-pointer-hover ${view === key ? "chosenLog" : ""}`} onClick={() => { setView(key); setDetail(value); setLogModal(true) }} >
                                                    <td>{value?.action}</td>
                                                    <td>{value?.userId}</td>
                                                    <td>{dateFormat(value?.createdAt)}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                                            : <TablePlaceHolder count={4} />
                                    }
                                </tbody>
                            </Table>
                            <Pagination pageNo={pageNo} changePage={setPageNo} next={next} />
                        </Col>
                        <Col md={5}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail?.previousData ? (
                                        renderTableRows(detail.previousData)
                                    ) : (
                                        <tr>
                                            <td colSpan="2" className="text-center">
                                                No previous data available.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={editModal} size="xl" toggle={() => setEditModal(false)}>
                <ModalHeader toggle={() => setEditModal(false)}>Role Update</ModalHeader>
                <ModalBody>
                    <Row className='my-2 flex-column'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="roleId" sm={3}>Role ID</Label>
                                <Col sm={5}>
                                    <Input disabled id="roleId" name="roleId" autoComplete='off' onChange={handleChange} value={roleId || ''} className='input-theme text-upper' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center mt-2'>
                                <Label for="name" sm={3}>Name</Label>
                                <Col sm={5}>
                                    <Input disabled={buttonDisable} id="name" name="name" autoComplete='off' onChange={(e) => setupdatedRoleName(e.target.value)} value={updatedRoleName || ''} className='input-theme text-upper' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="name" sm={3}>Role</Label>
                                <Col sm={5}>
                                    {
                                        (updatedRole && updatedRole?.length > 0) ?
                                            updatedRole?.map((element, i) => {
                                                return (
                                                    <Row key={i} className="mt-2">
                                                        <Col className="me-2">
                                                            <Select
                                                                styles={{
                                                                    control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                                    option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                                }}
                                                                options={controller}
                                                                isDisabled={buttonDisable}
                                                                value={{ label: element?.controller, value: element?.controller }}
                                                                onChange={(e) => handleChange(e, i)}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Select
                                                                styles={{
                                                                    control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                                    option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                                    valueContainer: ((style) => ({ ...style, overflow: "scroll" })),
                                                                    multiValueLabel: ((style) => ({ ...style, backgroundColor: "#ff4d32", borderRadius: " 2px 0 0 2px ", color: "white", })),
                                                                    multiValueRemove: ((style) => ({ ...style, border: "solid #ff4d32", borderRadius: "0 2px 2px 0", backgroundColor: "white", color: "ff4d32", }))
                                                                }}
                                                                isDisabled={buttonDisable}
                                                                defaultValue={element?.action?.map((items) => action?.find((val) => val?.value === items)) || []}
                                                                onChange={(e) => handleActionChange(e, i)}
                                                                isMulti options={action} />
                                                        </Col>
                                                    </Row>
                                                )
                                            }) : ""
                                    }
                                    {
                                        controller?.length > role?.length &&
                                        <Row className="p-0 text-end" sm={1}>
                                            <i className='bx cursor-pointer bx-plus bx-md' onClick={() => { handleNextSelect() }}></i>
                                        </Row>
                                    }
                                </Col>
                            </FormGroup>
                        </Col>

                        <Col>
                            <div className='text-center my-3'>
                                <Button className='button-theme' disabled={buttonDisable} onClick={onSubmit}>Update</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>


            <Container fluid>
                <Row className="justify-content-between" >
                    <Col className='text-center'><h3>Role View</h3></Col>
                </Row>
            </Container>
            <Row className="align-items-center justify-content-center">
                <Col md={6}>
                    <Card className="p-4 px-5">
                        <Row className="text-end"><i className='bx bxs-edit-alt gmd-color bx-sm cursor-pointer' onClick={() => { setupdatedRoleName(roleName); setUpdatedRole(role); setEditModal(true) }}></i></Row>
                        <FormGroup className="align-items-center" row>
                            <Label sm={4} > Role ID</Label>
                            <Col sm={8}>
                                <Label className="mb-0">{roleId}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="align-items-center" row>
                            <Label sm={4} > Role Name </Label>
                            <Col sm={8}>
                                <Label className="mb-0 text-capitalize">{roleName}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="align-items-center" row>
                            <Label sm={4} > Log </Label>
                            <Col sm={8} className="d-flex">
                                <i className='bx-sm cursor-pointer bx bx-info-circle' onClick={() => { setLogModal(true) }}></i>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} > Permissions </Label>
                            <Col sm={8}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Controllers</th>
                                            <th colSpan={3} className="text-center">Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            role && role?.length ?
                                                role?.map((element, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-capitalize">
                                                                {element?.controller}
                                                            </td>
                                                            <td> {element?.action && element?.action?.some(element => element === "read") ? <i className='bx bxs-check-circle green' ></i> : <i className='bx bxs-x-circle red'></i>}        Read</td>
                                                            <td>{element?.action && element?.action?.some(element => element === "write") ? <i className='bx bxs-check-circle green' ></i> : <i className='bx bxs-x-circle red'></i>} Write</td>
                                                            <td>{element?.action && element?.action?.some(element => element === "update") ? <i className='bx bxs-check-circle green' ></i> : <i className='bx bxs-x-circle red'></i>} Update</td>

                                                        </tr>
                                                    )
                                                })
                                                : ""
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default View;